import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { AddRounded, RemoveRounded } from "@mui/icons-material";

const ChangeQuantityComponent = ({ quantity, onPlusClicked, onMinusClicked, stockInformation, ...props }) => {
	const plusDisabled = React.useMemo(() => {
		return stockInformation?.availableAfterPurchase <= 0;
	}, [stockInformation]);

	const plusBtn = React.useMemo(() => {
		return (
			<IconButton
				aria-label="+1"
				onClick={() => {
					if (!plusDisabled) onPlusClicked?.();
				}}
				sx={{
					backgroundColor: (theme) => plusDisabled ? theme.palette.grey["300"] : "transparent",
					width: "45px",
					height: "45px",
					"& > svg": {
						color: (theme) => plusDisabled ? "grey" : theme.palette.secondary.main,
						transition: "color 0.3s,",
					},
					"&:hover": {
						backgroundColor: (theme) => plusDisabled ?  theme.palette.grey["300"] : theme.palette.secondary.main,
						border: "none",
						"& > svg": {
							color: (theme) => plusDisabled ? "grey" : "white",
						},
					},
				}}
			>
				<AddRounded color={"secondary"} />
			</IconButton>
		);
	}, [onPlusClicked, plusDisabled]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				border: (theme) => `${plusDisabled ? theme.palette.grey["300"] : theme.palette.secondary.main} 1px solid`,
				height: "45px",
				borderRadius: "30px",
			}}
		>
			<IconButton
				aria-label="-1"
				onClick={() => {
					onMinusClicked?.();
				}}
				sx={{
					backgroundColor: "transparent",
					width: "45px",
					height: "45px",
					"& > svg": {
						color: (theme) => theme.palette.secondary.main,
						transition: "color 0.3s,",
					},
					"&:hover": {
						backgroundColor: (theme) => theme.palette.secondary.main,
						border: "none",
						"& > svg": {
							color: "white",
						},
					},
				}}
			>
				<RemoveRounded color={"secondary"} />
			</IconButton>
			<Typography
				sx={{
					marginRight: 2,
					marginLeft: 2,
				}}
			>
				{quantity}
			</Typography>
			{plusDisabled ? (
				<Tooltip
					title={"Impossible d'ajouter plus de ce produit dans le panier, car il n'y a plus de stock disponible."}
				>
					{plusBtn}
				</Tooltip>
			) : (
				plusBtn
			)}
		</Box>
	);
};

export default ChangeQuantityComponent;
