import { configureStore } from "@reduxjs/toolkit";
import { api } from "./api/rtkApi";
import RootReducer from "./root";
import expireReducer from "redux-persist-expire";

import storage from "redux-persist/lib/storage";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { initialState } from "./reducers/basketSlice";

const persistConfig = {
	key: "root",
	storage,
	version: 1,
	whitelist: ['basket'],
	transforms: [
		expireReducer("basket", {
			expireSeconds: 3600 * 24 * 7, // 7 days
			expiredState: initialState,
			autoExpire: true
		})
	]
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

export const store = configureStore({
	devTools: true,
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		}).concat(api.middleware)
});

export const persistor = persistStore(store);

