import React from "react";
import { Grid, Typography } from "@mui/material";
import InexCard from "../components/InexCard";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import { useDispatch } from "react-redux";
import { resetBasket } from "../store/reducers/basketSlice";

const PaymentPending = () => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(resetBasket());
	}, []);
	return (
		<InexCard
			sx={{
				paddingLeft: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
				paddingRight: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					<Typography
						variant={"h4"}
						textAlign={"left"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding Bold",
							fontSize: 20,
							marginBottom: (theme) => theme.spacing(5),
						}}
					>
						{"Merci ! Votre commande est en cours de validation."}
					</Typography>
				</Grid>
			</Grid>
		</InexCard>
	);
};

export default PaymentPending;
