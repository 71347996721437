import React from "react";
import { Box, Chip } from "@mui/material";

const ProductCategoryChip = ({ name, ...props }) => {
	return (
		<Box {...props}>
			<Chip
				label={name}
				variant="outlined"
				sx={{
					borderRadius: 0,
					color: "#182B43",
					borderColor: "#182B43",
					textTransform: "uppercase",
				}}
			/>
		</Box>
	);
};

export default ProductCategoryChip;
