import { api } from "./rtkApi";

export const productApi = api.injectEndpoints({
	endpoints: (build) => ({
		getProducts: build.query({
			query: () => {
				return {
					method: "GET",
					url: `/product/list`,
				};
			},
		}),
		getProduct: build.query({
			query: ({ id }) => {
				return {
					method: "GET",
					url: `/product/get-product/?id=${id}`,
				};
			}
		}),
		getProductCategories: build.query({
			query: () => {
				return {
					method: "GET",
					url: `/product/get-categories`,
				};
			},
		}),
		getProductsByCategory: build.query({
			query: ({ idcat }) => {
				return {
					method: "GET",
					url: `/product/categorie/${idcat}`,
				};
			}
		})
	}),
	overrideExisting: true,
});

export const { useGetProductsQuery, useLazyGetProductsQuery, useGetProductCategoriesQuery, useGetProductQuery, useLazyGetProductQuery, useGetProductsByCategoryQuery } = productApi;
