import React from "react";
import { Box, Fade, Grid, Skeleton, styled, Typography, useTheme } from "@mui/material";
import { useGetLast4MagazinesQuery } from "../../../store/api/magazines";
import { ShoppingBagOutlined } from "@mui/icons-material";
import { CTAButton } from "./odyssee/OdysseeCard";
import { ReactComponent as MagazineBackground } from "../../../resources/images/svg/home/magazineBackground.svg";
import { ReactComponent as AppleIcon } from "../../../resources/images/svg/home/apple.svg";
import { ReactComponent as AndroidIcon } from "../../../resources/images/svg/home/android.svg";
import useIsOnScreen from "../../../hooks/useIsOnScreen";
import { useIsMobile } from "../../../hooks/responsive";

const NewMagazineTitle = styled(Typography)(({ theme }) => {
	return {
		textTransform: "uppercase",
		fontFamily: "Branding Bold",
		color: "white",
		textAlign: "center",
		background: "#EC609F",
		fontSize: "20px",
		lineHeight: "24px",
		letterSpacing: "3px",
		paddingRight: "15px",
		paddingLeft: "15px",
	};
});

const MagazineTitle = styled("svg")(({ theme, backgroundColor }) => {
	return {
		marginTop: theme.spacing(2),
		fontFamily: "Yesevaone",
		fontSize: "100%",
		lineHeight: "50px",
		textTransform: "uppercase",
		maxWidth: "100%",
		overflow: "hidden",
		color: theme.palette.getContrastText(backgroundColor),
	};
});

const ApplicationLink = styled("a")(({ theme, background }) => {
	return {
		color: theme.palette.getContrastText(background),
	};
});

const MagazineSubscriptionBlock = (props) => {
	const ref = React.useRef(null);

	const isVisible = useIsOnScreen(ref);

	const { data, isLoading, isError } = useGetLast4MagazinesQuery();
	const magazine = React.useMemo(() => {
		return data?.[0];
	}, [data]);

	const svgRef = React.useRef(null);

	React.useEffect(() => {
		const svg = svgRef.current;
		if (svg && magazine) {
			const paths = svg.querySelectorAll("path");
			paths.forEach((path) => {
				path.setAttribute("fill", `#${magazine.fond1}`);
			});
		}
	}, [svgRef.current, magazine]);

	const svg1Ref = React.useRef(null);
	const svg2Ref = React.useRef(null);

	const theme = useTheme();

	React.useEffect(() => {
		const svg1 = svg1Ref.current;
		const svg2 = svg2Ref.current;
		if (svg1 && svg2 && magazine && theme) {
			const paths1 = svg1.querySelectorAll("path");
			paths1.forEach((path) => {
				path.setAttribute("fill", theme.palette.getContrastText(`#${magazine.fond1}`));
			});
			let paths2 = [];
			paths2 = paths2.concat(
				svg2.querySelectorAll("path"),
				svg2.querySelectorAll("polygon"),
				svg2.querySelectorAll("polyline"),
			);
			paths2.forEach((paths) => {
				paths.forEach((path) => {
					path.setAttribute("fill", theme.palette.getContrastText(`#${magazine.fond1}`));
				});
			});
		}
	}, [svg1Ref.current, svg2Ref.current, magazine, theme]);

	const isMobile = useIsMobile();

	return (
		<Box
			ref={ref}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				background: magazine
					? `linear-gradient(90deg, #${magazine.fond1} 0%, #${magazine.fond2} 100%)`
					: "transparent",
				position: "relative",
				zIndex: 0,
			}}
		>
			{isLoading || isError || !magazine ? (
				<Skeleton variant="rounded" width={"100%"} height={553} />
			) : (
				<>
					<MagazineBackground
						ref={svgRef}
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							top: 0,
							left: 0,
						}}
					/>
					<Grid
						container
						p={3}
						sx={{
							zIndex: 1,
						}}
					>
						{isMobile && (
							<Grid item xs={12} md={0}>
								<Fade in={isVisible} timeout={500}>
									<Box
										sx={{
											display: "flex",
											height: "100%",
											width: "100%",
											"& > img": {
												objectFit: "contain",
												width: "100%",
											},
										}}
									>
										<img
											src={`https://medias.inrees.com/img/magazine/${magazine.id}_home.jpg`}
											alt={"magazineCover"}
										/>
									</Box>
								</Fade>
							</Grid>
						)}
						<Grid item xs={12} md={6} p={3}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									flexGrow: 1,
								}}
							>
								<Box
									sx={{
										marginRight: "auto",
										display: "flex",
										alignItems: "center",
									}}
								>
									<Fade in={isVisible} timeout={500}>
										<NewMagazineTitle>{`Nouveau - ${magazine.minititre}`}</NewMagazineTitle>
									</Fade>
								</Box>
								<Fade in={isVisible} timeout={500}>
									<MagazineTitle
										width="100%"
										height="100%"
										viewBox="0 0 500 75"
										preserveAspectRatio="xMinYMid meet"
										xmlns="http://www.w3.org/2000/svg"
										backgroundColor={`#${magazine.fond1}`}
									>
										<text x="0" y="60" font-size="60" fill="black">
											{magazine.titre}
										</text>
									</MagazineTitle>
									{/*<MagazineTitle*/}
									{/*	sx={{*/}
									{/*		color: (theme) => theme.palette.getContrastText(`#${magazine.fond1}`),*/}
									{/*	}}*/}
									{/*>*/}
									{/*	{magazine.titre}*/}
									{/*</MagazineTitle>*/}
								</Fade>
								<Fade in={isVisible} timeout={500}>
									<Typography
										sx={{
											color: (theme) => theme.palette.getContrastText(`#${magazine.fond1}`),
											marginTop: 2,
											lineHeight: "22px",
										}}
									>
										<div dangerouslySetInnerHTML={{ __html: magazine?.description }} />
									</Typography>
								</Fade>
								<a
									target="_blank"
									href={"https://abonnements.inexplore.com/step/subscription?selectedAbos=[2,3]"}
								>
									<CTAButton
										variant={"texticon"}
										icon={
											<ShoppingBagOutlined
												sx={{
													fontSize: "26px",
													color: (theme) =>
														theme.palette.getContrastText(`#${magazine.couleurCTA}`),
												}}
											/>
										}
										textWithGradient={false}
										disableBackground={true}
										text={"Je m'abonne"}
										sx={{
											width: isMobile ? "100%" : undefined,
											marginTop: 3,
											padding: 3.5,
											marginRight: "auto",
											flexDirection: "row-reverse",
											backgroundColor: `#${magazine.couleurCTA}`,
										}}
										textSx={{
											color: (theme) => theme.palette.getContrastText(`#${magazine.couleurCTA}`),
										}}
									/>
								</a>
							</Box>
						</Grid>
						{!isMobile && (
							<Grid item xs={0} sm={6} md={3.5}>
								<Fade in={isVisible} timeout={500}>
									<Box
										sx={{
											display: "flex",
											height: "100%",
											width: "100%",
											"& > img": {
												objectFit: "contain",
												width: "100%",
											},
										}}
									>
										<img
											src={`https://medias.inrees.com/img/magazine/${magazine.id}_home.jpg`}
											alt={"magazineCover"}
										/>
									</Box>
								</Fade>
							</Grid>
						)}
						<Grid item xs={12} sm={6} md={2.5} p={3} pl={5}>
							<Fade in={isVisible} timeout={500}>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										height: "100%",
										width: "100%",
										flexDirection: "column",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
											paddingLeft: 3,
											paddingRight: 3,
											"& > img": {
												objectFit: "cover",
												width: "100%",
											},
										}}
									>
										<img
											src={`https://medias.inrees.com/img/magazine/${magazine.id}_frame_mobile.png`}
											alt={"magazineInSmartphone"}
										/>
									</Box>
									<Typography
										sx={{
											marginTop: 1,
											fontSize: "16px",
											fontFamily: "Branding Bold",
											color: (theme) => theme.palette.getContrastText(`#${magazine.fond1}`),
										}}
									>
										Aussi en version numérique avec votre abonnement
									</Typography>
									<Typography
										sx={{
											marginTop: 1,
											fontSize: "14px",
											fontFamily: "Branding Medium",
											color: (theme) => theme.palette.getContrastText(`#${magazine.fond1}`),
										}}
									>
										Disponible via notre application sur smartphone
									</Typography>
									<Box
										sx={{
											marginTop: 3,
											display: "flex",
											alignItems: "center",
											justifyContent: "space-evenly",
											flexDirection: "row",
											width: "100%",
										}}
									>
										<ApplicationLink
											href="https://apps.apple.com/fr/developer/inrees/id1619523357"
											target="_blank"
											background={`#${magazine.fond1}`}
										>
											<AppleIcon ref={svg1Ref} />
										</ApplicationLink>
										<ApplicationLink
											href="https://play.google.com/store/apps/developer?id=INREES"
											target="_blank"
											background={`#${magazine.fond1}`}
										>
											<AndroidIcon ref={svg2Ref} />
										</ApplicationLink>
									</Box>
								</Box>
							</Fade>
						</Grid>
					</Grid>
				</>
			)}
		</Box>
	);
};

export default MagazineSubscriptionBlock;
