import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "snackbar",
	initialState: { show: false, text: "", severity: "success" },
	reducers: {
		setSnackbarData: (state, action) => {
			state.show = action.payload.show;
			state.text = action.payload.text;
			state.severity = action.payload.severity;
		},
		setShowSnackbar: (state, action) => {
			state.show = action.payload;
		},
		setSnackbarText: (state, action) => {
			state.text = action.payload;
		},
		setSnackbarSeverity: (state, action) => {
			state.severity = action.payload;
		},
	},
});

export default slice.reducer;
export const { setSnackbarData, setShowSnackbar, setSnackbarText, setSnackbarSeverity } = slice.actions;

export const getShowSnackbar = (state) => state.snackbar.show;
export const getSnackbarText = (state) => state.snackbar.text;
export const getSnackbarSeverity = (state) => state.snackbar.severity;
