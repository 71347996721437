import React from "react";
import { Box, Grid, styled } from "@mui/material";

export const NAVIGATION = [
	{ title: "Actualités", href: "https://www.inexplore.com/" },
	{ title: "Articles", href: "https://www.inexplore.com/articles" },
	{ title: "Podcasts", href: "https://www.inexplore.com/podcasts" },
	{ title: "Magazines", href: "https://www.inexplore.com/accueil-magazines" },
	{ title: "Odyssées", href: "https://www.inexplore.com/odyssee/ovni-vers-une-realite-extraterrestre" },
	{ title: "Boutique", href: "/" },
	{
		title: "S'abonner",
		style: {
			color: "#f3ca4e",
			fontWeight: 700,
		},
		href: "https://www.inexplore.com/s-abonner",
	},
	{ title: "Directs", href: "https://www.inexplore.com/directs", highlightedOnLive: true },
];

const Nav = styled("nav")(({ theme }) => {
	return {
		display: "flex",
		alignItems: "center",
	};
});
const List = styled("ul")(({ theme }) => {
	return {
		display: "flex",
		alignItems: "center",
	};
});
const Item = styled("li")(({ theme }) => {
	return {
		padding: "0 10px",
		justifyContent: "center",
	};
});

const Title = styled("a")(({ theme }) => {
	return {
		color: "#fbf0e5",
		fontSize: "15px",
		fontWeight: 300,
		height: "100%",
		lineHeight: "82px",
		position: "relative",
		textAlign: "center",
		verticalAlign: "middle",
		fontFamily: "OpenSans",
		display: "block",
		cursor: "pointer",
		"&:hover": {
			"&:after": {
				transform: "rotateY(0deg)",
			},
		},
		"&:after": {
			content: '""',
			display: "block",
			height: "0.1rem",
			width: "100%",
			position: "absolute",
			left: 0,
			bottom: 0,
			background: "#a5d0c9",
			transform: "rotateY(90deg)",
			transition: "all 0.15s ease-out",
		},
	};
});

const HeaderNav = () => {

	const [isLive, setIsLive] = React.useState(false);

	React.useEffect(() => {
		fetch("https://login-api-node-33dvsn7xmq-ew.a.run.app/lives/inLive").then((data) => {
			data.json().then((data) => {
				setIsLive(data);
			});
		});
	}, []);

	return (
		<Nav>
			<List>
				{NAVIGATION.map((item) => (
					<a
						href={item.href}
						key={item.title}
						style={{
							textDecoration: "none",
						}}
					>
						<Item
							key={item.title}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "row",
							}}
						>
							<Title
								sx={{
									...item?.style,
									color: item?.style?.color || "#fbf0e5",
									fontWeight: item?.style?.fontWeight || 300,
								}}
							>
								{item.title}
							</Title>
							{item.highlightedOnLive && isLive && (
								<Box
									sx={{
										backgroundColor: "#ef7d8f",
										width: "12px",
										height: "12px",
										minWidth: "12px",
										minHeight: "12px",
										borderRadius: "10px",
										animation: "wave 2s ease-in-out infinite",
										marginTop: "1px",
										marginLeft: "0.7rem",

									}}
								/>
							)}
						</Item>
					</a>
				))}
			</List>
		</Nav>
	);
};

export default HeaderNav;
