import React from "react";
import InexCard from "../components/InexCard";
import { Grid, Typography } from "@mui/material";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../store/reducers/basketSlice";
import { resetBasket } from "../store/reducers/basketSlice";
import InexButton from "../components/InexButton";

const PaymentSuccess = () => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const dispatch = useDispatch();
	const order = useSelector(getOrder);
	React.useEffect(() => {
		dispatch(resetBasket());
		if (process.env.NODE_ENV === "development" && order != null) {
			const idcommande = order?.idcommande;
			const queryString = window.location.search;
			const validateLink = process.env.REACT_APP_API_URL + "/order/paie/" + idcommande + queryString;
			window.open(validateLink, "_blank");
		}
	}, []);

	return (
		<InexCard
			sx={{
				paddingLeft: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
				paddingRight: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					<Typography
						variant={"h4"}
						textAlign={"center"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding Bold",
							fontSize: 24,
							marginBottom: (theme) => theme.spacing(2),
						}}
					>
						{"Félicitations ! Votre commande est validée !"}
					</Typography>
					<Typography
						variant={"h4"}
						textAlign={"center"}
						color={"secondary"}
						sx={{
							fontFamily: "Branding Bold",
							fontSize: 20,
							marginBottom: (theme) => theme.spacing(5),
						}}
					>
						{
							"Aller la consulter sur votre profil myInexploré"
						}
					</Typography>
				</Grid>
				<Grid iten xs={0} sm={0} md={1} lg={3} />
				<Grid item xs={12} sm={12} md={10} lg={6}>
					<InexButton
						text={"Aller sur mon profil"}
						onClick={() => {
							window.location.href = "https://my.inexplore.com/profil";
						}}
						sx={{
							width: "100%",
							height: "50px",
						}}
					/>
				</Grid>
				<Grid iten xs={0} sm={0} md={1} lg={3} />
			</Grid>
		</InexCard>
	);
};

export default PaymentSuccess;
