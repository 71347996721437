import React from "react";
import { Alert, CardContent, Chip, Grid, IconButton, styled, Tooltip, Typography } from "@mui/material";
import { CardGiftcardRounded, DeleteOutlineRounded } from "@mui/icons-material";
import InexCard from "../InexCard";
import { useBasket } from "../../hooks/basket";
import { useGetProductCategoriesQuery } from "../../store/api/product";
import InexButton from "../InexButton";
import ChangeQuantityComponent from "./ChangeQuantityComponent";
import { useIsMobile } from "../../hooks/responsive";
import { validationSteps } from "./ProductAddedToBasket/GiftSelectVariant";
import ProductCategoryChip from "./ProductCategoryChip";
import moment from "moment";
import { getUser } from "../../store/reducers/authSlice";
import { useSelector } from "react-redux";

const Price = styled(Typography)(({ theme, sx }) => {
	return {
		fontFamily: "Branding Bold",
		fontSize: "23px",
		...sx,
	};
});

const PriceAfterPromo = styled(Typography)(({ theme, sx }) => {
	return {
		fontFamily: "Branding Bold",
		fontSize: "23px",
		marginLeft: theme.spacing(1),
		...sx,
	};
});

export const GiftBackground = styled("div")(({ theme }) => {
	return {
		position: "absolute",
		background: theme.palette.primary.dark,
		height: "40px",
		width: "28px",

		top: "25px",
		left: "-5px",
		transform: "rotate(45deg)",
		zIndex: -1,
	};
});

export const GiftForeground = styled("div")(({ theme }) => {
	return {
		position: "absolute",
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "center",
		content: '"Cadeau"',
		top: "10px",
		left: "-15px",
		padding: "0.5rem",
		width: "10rem",
		background: theme.palette.primary.light,
		color: "white",
		textAlign: "center",
		fontFamily: "'Roboto', sans-serif",
		boxShadow: "4px 4px 15px rgba(26, 35, 126, 0.2)",
	};
});

const checkProductIsInWarning = (basketProduct) => {
	const gift = basketProduct?.infos?.gift ?? false;

	if (gift) {
		const isDigital = basketProduct?.infos?.giftInfos?.digital === 1;
		const infos = basketProduct?.infos?.giftInfos;
		if (!infos) return true;
		let isValid = true;
		Object.keys(infos ?? {}).forEach((key) => {
			const validationObj = validationSteps.find((e) => e.fieldName === key);
			if (validationObj && isValid === true) {
				if (key !== "telephone") {
					if (isDigital === false && key === "email") return;
					isValid = validationObj.checkFunc(infos[key]);
				}
			}
		});
		return isValid !== true;
	}
	return false;
};

export const useIsBasketProductWarning = (basketProduct) => {
	return React.useMemo(() => {
		return checkProductIsInWarning(basketProduct);
	}, [basketProduct]);
};

export const useIsBasketInWarning = () => {
	const { products } = useBasket();

	return products?.some((p) => {
		return checkProductIsInWarning(p);
	});
};

export const calculateProductPrice = (product, user) => {
	const now = new Date().getTime() / 1000;
	const promoKeys = [];
	if (user && !product?.gift) {
		if (user.mag > now) {
			promoKeys.push("reduct_mag");
		}
		if (user.digital > now && !(user.tv > now)) {
			promoKeys.push("reduct_digit");
		}
		if (user.tv > now) {
			promoKeys.push("reduct_tv");
		}
	}
	let promo = 0;
	promoKeys.forEach((key) => {
		promo += product[key] ?? 0;
	});
	const price = product?.prix ?? 0;
	return [(price - promo) / 100, promo / 100, price / 100];
};

export const useGetProductPrice = (product) => {
	const user = useSelector(getUser);

	return React.useMemo(() => {
		return calculateProductPrice(product, user);
	}, [product, user]);
};

const BasketProductCard = ({ basketProduct, onEditClicked, stockInformation }) => {
	const isMobile = useIsMobile();
	const { changeQuantity } = useBasket();

	const { data: dataCategories } = useGetProductCategoriesQuery();

	const product = React.useMemo(() => {
		return basketProduct.infos;
	}, [basketProduct]);

	const gift = React.useMemo(() => {
		return basketProduct?.infos?.gift ?? false;
	}, [basketProduct]);

	const productCategory = React.useMemo(() => {
		if (!dataCategories) return null;
		return dataCategories.find((e) => e.id === product?.idcat);
	}, [dataCategories, product]);

	const [priceNumber, promoNumber, priceBeforePromoNumber] = useGetProductPrice(product);

	const price = React.useMemo(() => {
		return priceNumber.toFixed(2).replace(".", ",");
	}, [priceNumber]);

	const priceBeforePromo = React.useMemo(() => {
		return priceBeforePromoNumber.toFixed(2).replace(".", ",");
	}, [priceBeforePromoNumber]);

	const isOdyssee = React.useMemo(() => {
		return productCategory?.id === 13;
	}, [productCategory]);

	const giftCardForName = React.useMemo(() => {
		if (!gift) return false;

		const giftInfos = basketProduct?.infos?.giftInfos;
		if (
			!giftInfos ||
			!giftInfos.lastName ||
			!giftInfos.firstName ||
			giftInfos.lastName === "" ||
			giftInfos.firstName === ""
		)
			return false;
		return `${giftInfos.firstName.charAt(0).toUpperCase() + giftInfos.firstName.substring(1)} ${giftInfos.lastName.toUpperCase()}`;
	}, [gift, basketProduct]);

	const isInWarning = useIsBasketProductWarning(basketProduct);

	const productAvailableDate = React.useMemo(() => {
		if (stockInformation?.disponible === "pré-vente") {
			const date = moment.unix(stockInformation.timestamp);
			return date.format("LL");
		}
		return null;
	}, [stockInformation]);

	return (
		<InexCard
			sx={{
				width: "100%",
				padding: 1,
				paddingTop: 1,
				paddingBottom: 1,
			}}
		>
			{gift && <GiftBackground />}
			<CardContent>
				<Grid container spacing={2}>
					<Grid
						item
						xs={4}
						sm={3}
						pr={1}
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						{isMobile && <ProductCategoryChip name={productCategory?.name} mt={gift ? 4 : 0} mb={1} />}
						<div
							style={{
								width: "100%",
								maxHeight: 200,
								minHeight: 100,
								height: "100%",
							}}
						>
							<img
								alt={"productImage"}
								src={`https://medias.inrees.com/img/produit/moyen_${product?.id}.jpg`}
								style={{
									width: "100%",
									height: "100%",
									objectFit: "contain",
								}}
							/>
						</div>
					</Grid>
					<Grid
						item
						container
						xs={8}
						sm={9}
						sx={{
							display: "flex",
						}}
					>
						<Grid
							item
							xs={12}
							container
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							{!isMobile ? (
								<Grid item>
									{productCategory && <ProductCategoryChip name={productCategory?.name} />}
								</Grid>
							) : (
								<Grid item />
							)}
							{isOdyssee ? (
								<Grid
									item
									sx={{
										display: "flex",
										justifyContent: "flex-end",
									}}
								>
									{gift === true && (
										<InexButton
											textWithGradient={false}
											text={"Modifier"}
											onClick={() => {
												onEditClicked?.(basketProduct);
											}}
											sx={{
												minWidth: "100px",
												backgroundColor: "transparent",
												border: (theme) => `1px solid ${theme.palette.secondary.main}`,
												"& > p": {
													color: (theme) => theme.palette.secondary.main,
												},
												"&:hover": {
													backgroundColor: (theme) => theme.palette.secondary.main,
													border: "none",
													"& > p": {
														color: "white",
													},
												},
											}}
										/>
									)}
									<IconButton
										onClick={() => {
											changeQuantity({
												id: basketProduct.id,
												quantity: 0,
											});
										}}
										sx={{
											marginLeft: 1,
											backgroundColor: "transparent",
											width: "45px",
											height: "45px",
											border: (theme) => `1px solid ${theme.palette.secondary.main}`,
											"& > svg": {
												color: (theme) => theme.palette.secondary.main,
											},
											"&:hover": {
												backgroundColor: (theme) => theme.palette.secondary.main,
												border: "none",
												"& > svg": {
													color: "white",
												},
											},
										}}
									>
										<DeleteOutlineRounded color={"secondary"} />
									</IconButton>
								</Grid>
							) : (
								<Grid
									item
									sx={{
										display: "flex",
										justifyContent: "flex-end",
									}}
								>
									<ChangeQuantityComponent
										quantity={basketProduct.quantity}
										stockInformation={stockInformation}
										onPlusClicked={() => {
											changeQuantity({
												id: basketProduct.id,
												quantity: basketProduct.quantity + 1,
											});
										}}
										onMinusClicked={() => {
											changeQuantity({
												id: basketProduct.id,
												quantity: basketProduct.quantity - 1,
											});
										}}
									/>
								</Grid>
							)}
						</Grid>
						<Grid item mt={1} xs={12}>
							<Typography
								sx={{
									fontFamily: "Branding Bold",
									fontSize: "20px",
								}}
							>
								{product?.titre}
							</Typography>
							<Typography>
								<div dangerouslySetInnerHTML={{ __html: product?.description }} />
							</Typography>
							{gift && (
								<>
									<Typography>
										{`Carte cadeau : envoi `}
										{basketProduct?.infos?.giftInfos?.digital ? " par email" : " postal"}
									</Typography>
									{giftCardForName && (
										<Typography>
											{`Pour : `}
											{giftCardForName && giftCardForName !== ""
												? giftCardForName
												: "Non renseigné"}
										</Typography>
									)}
								</>
							)}
						</Grid>
						<Grid
							container
							item
							xs={12}
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
								alignItems: "flex-end",
								marginTop: (theme) => theme.spacing(1),
							}}
						>
							<Tooltip
								title={promoNumber > 0 ? "Réduction octroyée grâce à vos abonnements inexploré" : ""}
							>
								<Price
									sx={{
										textDecoration: promoNumber > 0 ? "line-through" : undefined,
										fontSize: promoNumber > 0 ? "18px" : "23px",
										color: (theme) => (promoNumber > 0 ? theme.palette.primary.main : undefined),
									}}
								>
									{priceBeforePromo + "€"}
								</Price>
							</Tooltip>
							{promoNumber > 0 && (
								<Tooltip
									title={
										promoNumber > 0 ? "Réduction octroyée grâce à vos abonnements inexploré" : ""
									}
								>
									<PriceAfterPromo>{price + "€"}</PriceAfterPromo>{" "}
								</Tooltip>
							)}
						</Grid>
					</Grid>
				</Grid>
				{isInWarning && (
					<Grid item xs={12} mt={1}>
						<Alert severity={"error"}>
							Des informations obligatoires sont manquantes, veuillez cliquer sur "modifier" pour les
							ajouter.
						</Alert>
					</Grid>
				)}
				{stockInformation?.availableAfterPurchase < 0 && stockInformation?.isNumerique === false && (
					<Grid item xs={12} mt={2}>
						<Alert severity={"warning"}>
							Il ne reste plus assez de stock de ce produit, veuillez réduire la quantité.
						</Alert>
					</Grid>
				)}
				{stockInformation?.availableAfterPurchase < 10 &&
					stockInformation?.availableAfterPurchase > 0 &&
					stockInformation?.isNumerique === false && (
						<Grid item xs={12} mt={2}>
							<Alert severity={"info"}>Il reste moins de 10 unités en stock.</Alert>
						</Grid>
					)}
				{stockInformation?.disponible === "pré-vente" && (
					<Grid item xs={12} mt={2}>
						<Alert severity={"info"}>
							{`Produit en précommande, disponible dès le ${productAvailableDate}${gift ? ". Votre carte cadeau sera envoyée à la validation de la commande." : "."}`}
						</Alert>
					</Grid>
				)}
			</CardContent>
			{gift && (
				<GiftForeground>
					<CardGiftcardRounded
						sx={{
							marginRight: 2,
						}}
					/>
					<Typography>Cadeau</Typography>
				</GiftForeground>
			)}
		</InexCard>
	);
};

export default BasketProductCard;
