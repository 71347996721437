import React from "react";
import { useDispatch } from "react-redux";
import { setOrder } from "../store/reducers/basketSlice";
import { useIsMobile, useIsTablet } from "../hooks/responsive";
import { useNavigate } from "react-router-dom";
import InexCard from "../components/InexCard";
import { Alert, Box, CircularProgress, Grid, Typography } from "@mui/material";
import InexButton from "../components/InexButton";
import { useLazyGetOrderQuery } from "../store/api/order";

const PayOrder = () => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const formRef = React.useRef(null);
	const [isFormReady, setIsFormReady] = React.useState(false);

	const dispatch = useDispatch();

	const [getOrder] = useLazyGetOrderQuery();

	const [order, setOrderSlug] = React.useState(null);
	const [error, setError] = React.useState(false);

	const navigate = useNavigate();

	React.useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		if (urlParams.has("idcommande")) {
			try {
				getOrder({
					idcommande: urlParams.get("idcommande"),
				})
					.then(({ data }) => {
						if (data) {
							setOrderSlug(data);
							dispatch(setOrder(data));
						} else {
							setError(true);
						}
					})
					.catch((e) => {
						setError(true);
					});
			} catch (e) {
				console.error(e);
			}
		} else {
			navigate("/");
		}
	}, []);

	// when form is ready, redirect to payment.
	React.useEffect(() => {
		if (isFormReady === true) formRef.current.submit();
	}, [isFormReady]);

	if (error === true)
		return (
			<Grid container px={isTablet ? 1 : 6} py={isMobile ? 3 : 4} pb={10}>
				<Grid container px={isMobile ? 1 : isTablet ? 5 : 15} pt={isMobile ? 2 : 5}>
					<InexCard
						sx={{
							paddingLeft: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
							paddingRight: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Alert severity={"error"} variant={"filled"} icon={false}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
								}}
							>
								<Typography fontSize={20} mb={2}>
									La commande est introuvable
								</Typography>
								<InexButton
									onClick={() => {
										window.location.href = "https://my.inexplore.com/profil";
									}}
									variant={"text"}
									textWithGradient={false}
									text={"Retourner à myInexploré"}
									textSx={{
										color: "white",
									}}
									sx={{
										width: "100%",
										background: "transparent",
										borderColor: "white",
										"&:hover": {
											borderColor: "white",
											background: "#fff4",
										},
									}}
								/>
							</Box>
						</Alert>
					</InexCard>
				</Grid>
			</Grid>
		);

	return (
		<Grid container px={isTablet ? 1 : 6} py={isMobile ? 3 : 4} pb={10}>
			<Grid container px={isMobile ? 1 : isTablet ? 5 : 15} pt={isMobile ? 2 : 5}>
				<InexCard
					sx={{
						paddingLeft: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
						paddingRight: (theme) => theme.spacing(isMobile ? 2 : isTablet ? 5 : 10),
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CircularProgress size={100} />
				</InexCard>
				{!!order && (
					<form
						ref={(element) => {
							if (element) {
								formRef.current = element;
								setTimeout(() => {
									setIsFormReady(true);
								}, 500);
							}
						}}
						method="POST"
						action={order.lienpaiement}
					>
						{order.infocb.map((element) => {
							return (
								<input key={element.label} type="hidden" name={element.label} value={element.value} />
							);
						})}
					</form>
				)}
			</Grid>
		</Grid>
	);
};

export default PayOrder;
