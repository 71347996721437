import React from "react";
import { Box, Typography } from "@mui/material";

const DELAY_CONST = 20;

const TitleWord = ({ word, isLast, delay, ...props }) => {
	const [showAnimation, setShowAnimation] = React.useState(false);

	React.useEffect(() => {
		setTimeout(() => {
			setShowAnimation(true);
		}, delay);
	}, []);

	return (
		<Typography
			{...props}
			sx={{
				position: "relative",
				fontFamily: "Yesevaone",
				textAlign: "center",
				fontSize: "38px",
				textTransform: "uppercase",
				color: (theme) => theme.palette.secondary.main,
				paddingRight: !isLast ? "0.3em" : 0,
				zIndex: 0,
				...props.sx,
				"&::after": {
					background: (theme) => theme.palette.lightgrey,
					bottom: 0,
					content: '""',
					height: "37%",
					left: "-.5rem",
					position: "absolute",
					width: showAnimation ? `calc(100% + 0.5rem)` : 0,
					zIndex: -1,
					transition: `width ${word.length * DELAY_CONST}ms linear`,
				},
			}}
		>
			{word}
		</Typography>
	);
};

const MainTitle = ({ title, typoProps, ...props }) => {
	const titleAsArray = React.useMemo(() => {
		return title.split(" ");
	}, [title]);

	const lengthRef = React.useRef(150 / DELAY_CONST); // base value is calculated to get 150ms. This is the delay to start the animation.

	return (
		<Box
			sx={{
				position: "relative",
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				justifyContent: "center",
				alignItems: "center",
				paddingRight: "10px",
				paddingLeft: "10px",
			}}
			{...props}
		>
			{titleAsArray.map((word, index) => {
				const delay = lengthRef.current * DELAY_CONST;
				lengthRef.current += word.length;
				return (
					<TitleWord
						key={word}
						word={word}
						isLast={index === titleAsArray.length}
						delay={delay}
						{...typoProps}
					/>
				);
			})}
		</Box>
	);
};

export default MainTitle;
