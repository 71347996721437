import React from "react";
import InexButton from "../../InexButton";
import { Box } from "@mui/material";
import DigitalBlock from "./subscriptions/DigitalBlock";
import TVBlock from "./subscriptions/TVBlock";
import MagazineBlock from "./subscriptions/MagazineBlock";

const SubscriptionsGiftCardBlock = ({ ...props }) => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					width: "100%",
					marginBottom: (theme) => theme.spacing(4),
				}}
			>
				<DigitalBlock />
			</Box>
			<Box
				sx={{
					width: "100%",
					marginBottom: (theme) => theme.spacing(4),
				}}
			>
				<TVBlock />
			</Box>
			<Box
				sx={{
					width: "100%",
				}}
			>
				<MagazineBlock />
			</Box>
			<a target="_blank" href={"https://www.inexplore.com/s-abonner"}>
				<InexButton
					textWithGradient={false}
					disableBackground={true}
					text={"En savoir plus sur nos abonnements"}
					sx={{
						marginTop: 4,
						padding: 3.5,
						width: "250px",
						backgroundColor: (theme) => theme.palette.lightgrey,
						transition: "border-color 0.3s, background 0.3s, color 0.3s,",
						"& > p": {
							color: (theme) => theme.palette.secondary.main,
							transition: "color 0.3s,",
						},
						"&:hover": {
							backgroundColor: (theme) => theme.palette.secondary.main,
							border: "none",
							"& > p": {
								color: "white",
							},
						},
					}}
				/>
			</a>
		</Box>
	);
};

export default SubscriptionsGiftCardBlock;
