import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import InexButton from "../../InexButton";
import InexCard from "../../InexCard";
import { useIsMobile } from "../../../hooks/responsive";
import { CheckRounded } from "@mui/icons-material";

const Title = styled(Typography)(({ theme }) => {
	return {
		fontSize: "16px",
		fontFamily: "Branding Bold",
	};
});

const Subtitle = styled(Typography)(({ theme }) => {
	return {
		fontSize: "16px",
		fontFamily: "Branding Medium",
	};
});

const GiftVariantSelectionCard = ({ title, subtitle, onClick, icon, sx, selected, ...props }) => {
	const isMobile = useIsMobile();

	const Icon = icon;

	return (
		<InexCard
			sx={{
				borderRadius: (theme) => theme.spacing(2),
				padding: (theme) => theme.spacing(isMobile ? 2 : 4),
				paddingTop: (theme) => theme.spacing(isMobile ? 2 : 4),
				paddingBottom: (theme) => theme.spacing(isMobile ? 2 : 4),
				backgroundColor: selected ? "#DDEFEA" : "transparent",
				...sx,
			}}
			onClick={(e) => {
				onClick && onClick(e);
			}}
			selected={selected}
			{...props}
		>
			<Grid
				container
				sx={{
					display: "flex",
					alignItems: "center",
				}}
				columnSpacing={2}
			>
				<Grid
					xs={4}
					sm={2.5}
					md={1.5}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Icon />
				</Grid>
				<Grid xs={8} sm={6} md={7} pr={1}>
					<Title>{title}</Title>
					<Subtitle>{subtitle}</Subtitle>
				</Grid>
				<Grid xs={12} sm={3.5} md={3.5} pl={isMobile ? 2 : 0} pt={isMobile ? 1 : 0}>
					<InexButton
						text={"Sélectionner"}
						icon={<CheckRounded color={"secondary"} />}
						variant={selected ? "icon" : "text"}
						disableBackground={true}
						textWithGradient={false}
						sx={{
							width: "100%",
							backgroundColor: selected ? "rgba(148,141,141,0.2)" : "transparent",
							transition: "border-color 0.3s, background 0.3s, color 0.3s,",
							border: (theme) => `1px solid ${theme.palette.secondary.main}`,
							"& > p": {
								color: (theme) => theme.palette.secondary.main,
								transition: "color 0.3s,",
							},
							"&:hover": !selected
								? {
										backgroundColor: (theme) => theme.palette.secondary.main,
										border: "none",
										"& > p": {
											color: "white",
										},
									}
								: {},
						}}
					/>
				</Grid>
			</Grid>
		</InexCard>
	);
};

export default GiftVariantSelectionCard;
