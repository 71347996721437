import { api } from "./rtkApi";
import { encode } from "querystring";
import { store } from "../store";

export const basketApi = api.injectEndpoints({
	endpoints: (build) => ({
		getBasket: build.query({
			query: ({ authToken }) => {
				return {
					method: "GET",
					url: `/cart/get`,
					headers: {
						authorization: "Bearer " + authToken,
					},
				};
			},
		}),
		addProductToBasket: build.mutation({
			query: ({ productId, quantity, idcat, infos, authToken }) => {
				return {
					method: "POST",
					url: `/cart/add`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/json",
					},
					body: {
						productId,
						quantity,
						idcat,
						infos,
					},
				};
			},
		}),
		editProductInBasket: build.mutation({
			query: ({ id, quantity, infos, authToken }) => {
				return {
					method: "PATCH",
					url: `/cart/update/${id}`,
					headers: {
						authorization: "Bearer " + authToken,
						"Content-Type": "application/json",
					},
					body: {
						quantity,
						infos,
					},
				};
			},
		}),
		checkBasketStockAvailability: build.query({
			query: ({ ids }) => {
				const ts = new Date().getTime();
				return {
					method: "GET",
					url: `/cart/check-stock?ids=${JSON.stringify(ids)}&ts=${ts}`,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetBasketQuery,
	useLazyGetBasketQuery,
	useAddProductToBasketMutation,
	useEditProductInBasketMutation,
	useCheckBasketStockAvailabilityQuery,
	useLazyCheckBasketStockAvailabilityQuery,
} = basketApi;
