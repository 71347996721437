import React from "react";
import { useGetUniversQuery } from "../../store/api/app";
import { Box, Fade, styled, Typography } from "@mui/material";
import earth from "../../resources/images/svg/earth.svg";
import chevron_down from "../../resources/images/svg/dropdown_arrow.svg";

const BoxUnivers = styled(Box)(({ theme }) => {
	return {
		pointer: "cursor",
		opacity: 0,
		transition: theme.transitions.create(["transform", "opacity", "visibility"]),
		visibility: "hidden",
		"&.isActive": {
			transform: "translateY(0)",
			opacity: 1,
			visibility: "visible",
		},
	};
});

const BoxSubUnivers = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		borderRadius: 15,
		margin: "auto",
		pointer: "cursor",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#595075",
	};
});

const UniversImage = styled("img")(({ theme }) => {
	return {
		width: "100%",
		objectFit: "contain",
	};
});

const UniversLink = styled("a")(({ theme }) => {
	return {
		width: 150,
		height: 100,
		display: "flex",
		borderRadius: 6,
		marginTop: 12,
		marginBottom: 12,
		cursor: "pointer",
	};
});

const UniversesList = ({ isOpen = true, inSubMenu = false, absoluteStyleProps, ...props }) => {
	const { data: univers } = useGetUniversQuery();

	const absoluteStyleDefault = {
		position: "absolute",
		top: 0,
		right: 0,
		transform: "translateY(-15%)",
		paddingTop: 10,
	};

	const absoluteStyle = absoluteStyleProps || absoluteStyleDefault;

	return (
		<BoxUnivers className={isOpen ? "isActive" : ""} sx={inSubMenu ? absoluteStyle : {}}>
			<BoxSubUnivers
				sx={
					{
						// backgroundColor: inSubMenu ? "rgba(255,255,255,0.5)" : "none",
					}
				}
			>
				{univers?.map((item, index) => (
					<UniversLink
						href={item.lien}
						key={item.titre}
						sx={{
							background: `#${item.background}`,
							marginLeft: index === 0 ? "12px" : "6px",
							marginRight: index === 1 ? "12px" : "6px",
						}}
					>
						<UniversImage src={item.logo} alt={item.titre} />
					</UniversLink>
				))}
			</BoxSubUnivers>
		</BoxUnivers>
	);
};

export default UniversesList;
