import React from "react";
import { useGetUniversQuery } from "../../store/api/app";
import { Box, Fade, styled, Typography } from "@mui/material";
import UniversesList from "./UniversesList";

import { ReactComponent as Earth } from "../../resources/images/svg/earth.svg";
import { ChevronLeftRounded } from "@mui/icons-material";

const BoxUniversContainer = styled(Box)(({ theme }) => {
	return {
		height: 45,
		width: 160,
		background: "linear-gradient(117.78deg, #2e3550 0%, #72538f 100%)",
		color: "white",
		borderRadius: 24,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		paddingLeft: 10,
		paddingRight: 10,
		transition: theme.transitions.create(["box-shadow"]),
		cursor: "pointer",
		position: "relative",
		"&.isActive": {
			boxShadow: "0 0 15px 0 #282d40",
			"& .univers-chevron": {
				transform: "rotate(180deg)",
			},
		},
		"& .univers-chevron": {
			transition: theme.transitions.create(["transform"]),
		},
	};
});

const LogoContainer = styled(Box)(({ theme }) => {
	return {
		width: 30,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};
});

const Universe = () => {
	const { data: univers } = useGetUniversQuery();
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<Fade in={!!univers}>
			<Box
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
				sx={{ position: "relative" }}
			>
				<BoxUniversContainer className={isOpen ? "isActive" : ""}>
					<LogoContainer>
						<Earth />
					</LogoContainer>
					<Typography fontFamily={"Branding Medium"} fontWeight={"600"}>
						Univers
					</Typography>
					<LogoContainer>
						<ChevronLeftRounded
							className={"univers-chevron"}
							size={"medium"}
							style={{
								color: "white",
								transform: `rotate(${isOpen ? "90deg" : "-90deg"})`
							}}
						/>
					</LogoContainer>
				</BoxUniversContainer>
				<UniversesList isOpen={isOpen} inSubMenu={true} />
			</Box>
		</Fade>
	);
};

export default Universe;
