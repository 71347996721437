import React from "react";
import { Grid, Menu, MenuItem, styled, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { getUser } from "../../store/reducers/authSlice";
import { useLogUserIn, useLogUserOut } from "../../hooks/auth";

const MainContainer = styled(Grid)(({ theme }) => {
	return {
		width: "45px",
		height: "45px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "25px",
		backgroundColor: "rgba(36, 38, 56, 0.53)",
		"&:hover": {
			cursor: "pointer",
		},
	};
});

const UserTypography = styled(Typography)(({ theme }) => {
	return {
		fontFamily: "Yesevaone serif",
		fontWeight: 600,
		fontSize: "20px",
		pointerEvents: "none",
		color: "white",
	};
});

const UserAvatar = () => {
	useLogUserIn();

	const logout = useLogUserOut();

	const theme = useTheme();
	const user = useSelector(getUser);

	const [anchorElement, setAnchorElement] = React.useState(false);
	const handleClose = () => {
		setAnchorElement(false);
	};

	return (
		<>
			<MainContainer
				onClick={(event) => {
					setAnchorElement(event.target);
				}}
			>
				{!!user && (
					<UserTypography>{`${user?.prenom[0]?.toUpperCase()}${user?.nom[0]?.toUpperCase()}`}</UserTypography>
				)}
			</MainContainer>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				anchorEl={anchorElement}
				open={!!anchorElement}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<a
					href={"https://my.inexplore.com/profil"}
					style={{
						color: theme.palette.secondary.main,
						textDecoration: "none",
					}}
				>
					<MenuItem>Mon espace client</MenuItem>
				</a>
				<MenuItem onClick={logout}>Se déconnecter</MenuItem>
			</Menu>
		</>
	);
};

export default UserAvatar;
