import React from "react";

// hook that returns if a given ref is visible on screen or not using the IntersectionObserver from the browser.
const useIsOnScreen = (ref) => {
	const [isIntersecting, setIntersecting] = React.useState(false);

	const observer = React.useMemo(
		() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
		[ref],
	);

	React.useEffect(() => {
		if (ref?.current) {
			observer.observe(ref.current);
			return () => observer.disconnect();
		}
	}, [ref]);

	return isIntersecting;
};

export default useIsOnScreen;
