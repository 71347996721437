import React from "react";
import OdysseeCard from "./odyssee/OdysseeCard";
import { Box } from "@mui/material";
import InexButton from "../../InexButton";

const OdysseeMainBlock = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<OdysseeCard />
			<a href={"https://www.inexplore.com/odyssee/ovni-vers-une-realite-extraterrestre"}>
				<InexButton
					textWithGradient={false}
					disableBackground={true}
					text={"En savoir plus"}
					sx={{
						marginTop: 4,
						padding: 3.5,
						width: "250px",
						backgroundColor: (theme) => theme.palette.lightgrey,
						transition: "border-color 0.3s, background 0.3s, color 0.3s,",
						"& > p": {
							color: (theme) => theme.palette.secondary.main,
							transition: "color 0.3s,",
						},
						"&:hover": {
							backgroundColor: (theme) => theme.palette.secondary.main,
							border: "none",
							"& > p": {
								color: "white",
							},
						},
					}}
				/>
			</a>
		</Box>
	);
};

export default OdysseeMainBlock;
