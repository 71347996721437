import React from "react";
import { Box, Fade, Typography } from "@mui/material";
import InexploreSectionTag from "../../InexploreSectionTag";
import inexploreIcon from "../../../../resources/images/svg/inexplore-logo-white.svg";
import useIsOnScreen from "../../../../hooks/useIsOnScreen";

const OdysseeGiftCard = ({ image, width = 160, delay = 0, ...props }) => {
	const ref = React.useRef(null);

	const isVisible = useIsOnScreen(ref);
	const [show, setShow] = React.useState(false);

	React.useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, delay);
	}, []);

	return (
		<Fade in={isVisible && show} timeout={500}>
			<Box
				ref={ref}
				sx={{
					display: "flex",
					position: "relative",
					alignItems: "center",
					justifyContent: "center",
					overflow: "hidden",
					borderRadius: (theme) => theme.spacing(1),
					boxShadow: (theme) => theme.shadows[26],
					transform: "rotate(-10deg)",
					width,
					minWidth: width,
					aspectRatio: 85 / 55,
					"& > img#odysseeImage": {
						width: "100%",
						position: "absolute",
						objectFit: "cover",
						zIndex: -1,
					},
				}}
			>
				<Box
					sx={{
						position: "absolute",
						top: "10px",
						left: "10px",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<img
						alt={"inexploreIcon"}
						src={inexploreIcon}
						style={{
							width: "20%",
						}}
					/>
					<Typography
						sx={{
							fontFamily: "Branding Bold",
							fontSize: "7px",
							color: "grey",
						}}
					>
						{"Odyssées"}
					</Typography>
				</Box>
				<img src={image} alt={"OdysseeImage"} id={"odysseeImage"} />
			</Box>
		</Fade>
	);
};

export default OdysseeGiftCard;
