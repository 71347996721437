import React from "react";
import { Collapse, Grid, styled, Typography } from "@mui/material";
import GiftVariantSelectionCard from "./GiftVariantSelectionCard";
import { ReactComponent as CloudIcon } from "../../../resources/images/svg/basket/cloud.svg";
import { ReactComponent as MailBoxIcon } from "../../../resources/images/svg/basket/mailbox.svg";
import InexTextField from "../../InexTextField";
import { useDispatch } from "react-redux";
import { editProduct } from "../../../store/reducers/basketSlice";

export const validationSteps = [
	{
		fieldName: "firstName",
		errorMessage: "Veuillez entrer un prénom",
		required: true,
		checkFunc: (val) => {
			return val && val !== "";
		},
	},
	{
		fieldName: "lastName",
		errorMessage: "Veuillez entrer un nom de famille",
		required: true,
		checkFunc: (val) => {
			return val && val !== "";
		},
	},
	{
		fieldName: "email",
		errorMessage: "Veuillez entrer un email valide",
		required: true,
		checkFunc: (email) => {
			const regex =
				/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return regex.test(email);
		},
	},
	{
		fieldName: "telephone",
		errorMessage: "Veuillez entrer un numéro de téléphone valide",
		required: false,
		checkFunc: (phone) => {
			const regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
			return regex.test(phone);
		},
	},
];

const Subtitle = styled(Typography)(({ theme }) => {
	return {
		fontSize: "16px",
		fontFamily: "Branding Bold",
	};
});

const GiftSelectVariant = ({ formIsValid, setFormIsValid, basketProduct, checkOnOpen = false, ...props }) => {
	const [error, setError] = React.useState({});

	const productRef = React.useRef(basketProduct);
	React.useEffect(() => {
		productRef.current = basketProduct;
	}, [basketProduct]);

	const startedFillFormRef = React.useRef(false);
	const dispatch = useDispatch();
	const reduceFunc = (state, action) => {
		startedFillFormRef.current = true;
		return { ...state, [action.field]: action.value };
	};
	const [formValues, setFormValue] = React.useReducer(reduceFunc, {
		digital: basketProduct?.infos?.giftInfos?.digital ?? 0,
		firstName: basketProduct?.infos?.giftInfos?.firstName ?? "",
		lastName: basketProduct?.infos?.giftInfos?.lastName ?? "",
		email: basketProduct?.infos?.giftInfos?.email ?? "",
		telephone: basketProduct?.infos?.giftInfos?.telephone ?? "",
	});
	const formValuesRef = React.useRef(formValues);
	React.useEffect(() => {
		formValuesRef.current = formValues;
	}, [formValues]);

	React.useEffect(() => {
		return () => {
			dispatch(
				editProduct({
					id: productRef.current.id,
					infos: { ...productRef.current.infos, giftInfos: formValuesRef.current },
				}),
			);
		};
	}, []);

	React.useEffect(() => {
		if (!basketProduct?.infos?.giftInfos) setFormValue({ field: "digital", value: 0 });
	}, [basketProduct]);

	const handleValidation = (fieldName, setIfError = true) => {
		let hasError = false;
		const tmp = {};
		if (!fieldName)
			validationSteps.forEach((element) => {
				if (element.checkFunc(formValues[element.fieldName]) === false && element.required === true) {
					tmp[element.fieldName] = element.errorMessage;
					hasError = true;
				} else {
					tmp[element.fieldName] = undefined;
				}
			});
		else {
			const validationStepIdx = validationSteps.findIndex((e) => e.fieldName === fieldName);
			if (validationStepIdx !== -1) {
				for (let i = 0; i <= validationStepIdx; i++) {
					const validationStep = validationSteps[i];
					if (
						validationStep.checkFunc(formValues[validationStep.fieldName]) === false &&
						validationStep.required === true
					)
						tmp[validationStep.fieldName] = validationStep.errorMessage;
					else tmp[validationStep.fieldName] = undefined;
				}
			}
		}
		if (setIfError) setError(tmp);
		return hasError;
	};

	React.useEffect(() => {
		if (checkOnOpen === true) setFormIsValid(handleValidation(undefined, true) === false);
	}, []);

	React.useEffect(() => {
		if (formValues["digital"] === 0) setFormIsValid(true);
		else if (startedFillFormRef.current === true) {
			setFormIsValid(handleValidation(undefined, false) === false);
		}
	}, [formValues]);

	return (
		<Grid xs={12} container rowSpacing={2} mt={2} mb={2}>
			<Grid item xs={12} mt={2}>
				<Subtitle>Saisissez les nom et prénom du destinataire de la carte :</Subtitle>
			</Grid>
			<Grid container item xs={12} spacing={2}>
				<Grid item xs={12} md={6}>
					<InexTextField
						label={"Prénom *"}
						value={formValues["firstName"]}
						onChange={(e) => {
							setFormValue({ field: "firstName", value: e.target.value });
						}}
						onBlur={() => {
							handleValidation("firstName");
						}}
						formControlSx={{
							width: "100%",
						}}
						error={error["firstName"] !== undefined}
						helperText={error["firstName"]}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<InexTextField
						label={"Nom *"}
						value={formValues["lastName"]}
						onChange={(e) => {
							setFormValue({ field: "lastName", value: e.target.value });
						}}
						onBlur={() => {
							handleValidation("lastName");
						}}
						formControlSx={{
							width: "100%",
						}}
						error={error["lastName"] !== undefined}
						helperText={error["lastName"]}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Subtitle
					sx={{
						fontSize: "12px",
						color: formIsValid ? '':'error.main'
					}}
				>
					* Champs obligatoires
				</Subtitle>
			</Grid>
			<Grid item xs={12}>
				<Subtitle
					sx={{
						marginTop: (theme) => theme.spacing(2),
					}}
				>
					Choisissez le mode d'envoi de la carte cadeau :
				</Subtitle>
			</Grid>
			<Grid item xs={12}>
				<GiftVariantSelectionCard
					icon={CloudIcon}
					title={"Envoyer la carte-cadeau numérique par email"}
					subtitle={"Avec ce mode d’envoi, le destinataire recevra la carte cadeau dans sa boite mail, où il retrouvera le code d’activation de son abonnement."}
					selected={formValues["digital"] === 1}
					onClick={() => {
						setFormValue({ field: "digital", value: 1 });
					}}
				/>
			</Grid>
			<Collapse
				in={formValues["digital"] === 1}
				sx={{
					marginTop: formValues["digital"] === 1 ? 2 : 0,
					width: "100%",
				}}
			>
				<Subtitle>Veuillez préciser les informations suivantes :</Subtitle>
				<Grid container mt={0} mb={1} spacing={2}>
					<Grid item xs={12}>
						<InexTextField
							label={"Email du destinataire *"}
							value={formValues["email"]}
							onChange={(e) => {
								setFormValue({ field: "email", value: e.target.value });
							}}
							onBlur={() => {
								handleValidation("email");
							}}
							formControlSx={{
								width: "100%",
							}}
							error={error["email"] !== undefined}
							helperText={error["email"]}
						/>
					</Grid>
					<Grid item xs={12}>
						<InexTextField
							label={"Téléphone du destinataire"}
							value={formValues["telephone"]}
							onChange={(e) => {
								setFormValue({ field: "telephone", value: e.target.value });
							}}
							onBlur={() => {
								handleValidation("telephone");
							}}
							formControlSx={{
								width: "100%",
							}}
							error={error["telephone"] !== undefined}
							helperText={error["telephone"]}
						/>
					</Grid>
				</Grid>
			</Collapse>
			<Grid item xs={12}>
				<GiftVariantSelectionCard
					icon={MailBoxIcon}
					title={"Recevoir la carte-cadeau par voie postale"}
					subtitle={
						"Avec ce mode d'envoi, vous recevrez la carte cadeau dans votre boite aux lettres, et vous pourrez ensuite la donner à son destinataire"
					}
					sx={{
						marginBottom: (theme) => theme.spacing(2),
					}}
					selected={formValues["digital"] === 0}
					onClick={() => {
						setFormValue({ field: "digital", value: 0 });
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default GiftSelectVariant;
