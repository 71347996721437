import React from "react";
import { Box, styled, Typography } from "@mui/material";
import compassIcon from "../../resources/images/svg/odysee/compassIcon.svg";
import inexploreIcon from "../../resources/images/svg/odysee/inexploreBlue.svg";

const InexploreMainProductTag = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "25px",
		height: "45px",
		width: "min-content",
		padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
		background: theme.palette.lightgrey + "80",
	};
});

const InexploreSectionTagImg = styled("img")(({ theme }) => {
	return { marginRight: theme.spacing(1) };
});

const InexploreSectionTag = ({ tagText = "Odyssées", textColor = "#EF7D8F", ...props }) => {
	return (
		<InexploreMainProductTag>
			<InexploreSectionTagImg alt={"compassIcon"} src={compassIcon} />
			<InexploreSectionTagImg alt={"inexploreIcon"} src={inexploreIcon} />
			<Typography
				sx={{
					fontFamily: "Branding Bold",
					fontSize: "21px",
					color: textColor,
				}}
			>
				{tagText}
			</Typography>
		</InexploreMainProductTag>
	);
};

export default InexploreSectionTag;
