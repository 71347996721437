import React from "react";
import { Box } from "@mui/material";
import InexButton from "../../InexButton";
import ListMagazines from "./magazines/ListMagazines";
import { useGetProductsByCategoryQuery } from "../../../store/api/product";

const MagazinesMainBlock = ({ ...props }) => {
	const { data } = useGetProductsByCategoryQuery({ idcat: 7 });

	const products = React.useMemo(() => {
		return data?.produits ? data.produits : null;
	}, [data]);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<ListMagazines products={products} />
			<a href={"https://www.inexplore.com/magazines"}>
				<InexButton
					textWithGradient={false}
					disableBackground={true}
					text={"Voir plus de magazines"}
					sx={{
						marginTop: 4,
						padding: 3.5,
						width: "250px",
						backgroundColor: (theme) => theme.palette.lightgrey,
						transition: "border-color 0.3s, background 0.3s, color 0.3s,",
						"& > p": {
							color: (theme) => theme.palette.secondary.main,
							transition: "color 0.3s,",
						},
						"&:hover": {
							backgroundColor: (theme) => theme.palette.secondary.main,
							border: "none",
							"& > p": {
								color: "white",
							},
						},
					}}
				/>
			</a>
		</Box>
	);
};

export default MagazinesMainBlock;
