import React from "react";
import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import { useLogUserIn } from "../../hooks/auth";
import { useSelector } from "react-redux";
import { getUser } from "../../store/reducers/authSlice";
import { ReactComponent as LoopIcon } from "../../resources/images/svg/loop.svg";
import { ReactComponent as HeartIcon } from "../../resources/images/svg/heart.svg";
import { NAVIGATION } from "./HeaderNav";
import userLogo from "../../resources/images/svg/header/user.svg";

const MenuItemLink = styled("a")(() => {
	return {
		fontFamily: "Yesevaone",
		color: "#fff",
		fontSize: 25,
		cursor: "pointer",
		textDecoration: "none",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	};
});
const Divider = styled(Box)(() => {
	return {
		backgroundColor: "#c7c4de",
		width: "100%",
		height: "2px",
	};
});

const ItemTypo = styled("a")(() => {
	return {
		fontFamily: "Branding SemiBold",
		color: "#fff",
		fontSize: 16,
	};
});

const UserContainer = styled(Grid)(({ theme }) => {
	return {
		width: "25px",
		height: "25px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "25px",
		backgroundColor: "rgba(36,38,56,.53)",
		"&:hover": {
			cursor: "pointer",
		},
	};
});

const UserTypography = styled(Typography)(({ theme }) => {
	return {
		color: "white",
		fontFamily: "Yesevaone",
		fontSize: "12px",
		pointerEvents: "none",
	};
});

const ItemContainer = styled(Box)(() => {
	return {
		display: "flex",
		gap: 15,
		alignItems: "center",
		cursor: "pointer",
	};
});

const LeftItemContainer = styled(Box)(() => {
	return {
		width: 30,
		display: "flex",
		justifyContent: "center",
	};
});

const MenuPanelContent = ({ redirect }) => {
	useLogUserIn();

	const user = useSelector(getUser);

	const [isLive, setIsLive] = React.useState(false);

	React.useEffect(() => {
		fetch("https://login-api-node-33dvsn7xmq-ew.a.run.app/lives/inLive").then((data) => {
			data.json().then((data) => {
				setIsLive(data);
			});
		});
	}, []);

	return (
		<Stack mx={"20px"} spacing={4}>
			<Stack spacing={2}>
				<ItemContainer onClick={() => redirect("/")}>
					<LeftItemContainer>
						<UserContainer onClick={(event) => {}}>
							{user ? (
								<UserTypography>{`${user?.prenom[0]?.toUpperCase()}${user?.nom[0]?.toUpperCase()}`}</UserTypography>
							) : (
								<img src={userLogo} alt={"user-logo"} />
							)}
						</UserContainer>
					</LeftItemContainer>
					<ItemTypo>Mon profil</ItemTypo>
				</ItemContainer>
				<ItemContainer onClick={() => redirect("/recherche")}>
					<LeftItemContainer>
						<LoopIcon width={24} height={24} />
					</LeftItemContainer>
					<ItemTypo>Recherche</ItemTypo>
				</ItemContainer>
				<ItemContainer onClick={() => redirect("/favoris")}>
					<LeftItemContainer>
						<HeartIcon width={30} height={30} />
					</LeftItemContainer>
					<ItemTypo>Favoris</ItemTypo>
				</ItemContainer>
			</Stack>
			<Divider />
			{NAVIGATION.map((item, index) => {
				return (
					<MenuItemLink
						key={item.title}
						href={item.href}
						sx={{
							...item?.style,
							color: item?.style?.color || "#fbf0e5",
							fontWeight: item?.style?.fontWeight || 300,
						}}
					>
						{item.title}
						{item.highlightedOnLive && isLive && (
							<Box
								sx={{
									backgroundColor: "#ef7d8f",
									width: "12px",
									height: "12px",
									minWidth: "12px",
									minHeight: "12px",
									borderRadius: "10px",
									animation: "wave 2s ease-in-out infinite",
									marginTop: "1px",
									marginLeft: "0.7rem",
								}}
							/>
						)}
					</MenuItemLink>
				);
			})}
		</Stack>
	);
};

export default MenuPanelContent;
