import React from "react";
import { Box } from "@mui/material";

import flowers0 from "../../resources/images/svg/home/fondFleurs0.svg";
import flowers1 from "../../resources/images/svg/home/fondFleurs1.svg";
import flowers2 from "../../resources/images/svg/home/fondFleurs2.svg";

const flowersTable = [flowers0, flowers1, flowers2];

const FlowersBackground = ({ flowersIndex = 0, ...props }) => {
	return (
		<Box
			{...props}
			sx={{
				position: "absolute",
				top: flowersIndex * 750,
				[(flowersIndex + 1) % 2 ? "left" : "right"]: 0,
				pointerEvents: "none",
				...props.sx,
			}}
		>
			<img src={flowersTable[flowersIndex % flowersTable.length]} alt={"flowers"} />
		</Box>
	);
};

export default FlowersBackground;
