import React from "react";
import { Box, Divider, Fade, Grid, styled, Typography } from "@mui/material";
import odysseeImg from "../../../../resources/images/png/home/odyssee.png";
import InexCard from "../../../InexCard";
import useIsOnScreen from "../../../../hooks/useIsOnScreen";
import { useIsMobile } from "../../../../hooks/responsive";
import OdysseeFeaturePoint from "./OdysseeFeaturePoint";
import {
	CardGiftcardRounded,
	CategoryRounded,
	FolderCopyRounded,
	MovieRounded,
	ShoppingBagOutlined,
} from "@mui/icons-material";
import InexButton from "../../../InexButton";
import OdysseeGiftCard from "./OdysseeGiftCard";
import moment from "moment";

const ImageContainer = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		position: "relative",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		height: "100%",
		width: "100%",
		"& > img": {
			position: "absolute",
			top: 0,
			left: 0,
			objectFit: "cover",
			width: "100%",
			height: "100%",
		},
	};
});

const TagLineTypo = styled(Typography)(({ theme }) => {
	return {
		color: theme.palette.yellow,
		fontSize: "18px",
		textTransform: "uppercase",
		fontFamily: "Branding Medium",
		marginRight: theme.spacing(2),
	};
});

const OdysseePriceBlock = styled(Box)(({ theme, hasPromo = false }) => {
	return {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "center",
		padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
		backgroundColor: theme.palette.primary.main,
		marginTop: "-15px",
		// height: hasPromo ? "80px" : "50px",
	};
});

const OdysseeTitle = styled(Typography)(({ theme }) => {
	return {
		color: "white",
		fontFamily: "Branding Bold",
		fontSize: "37px",
		lineHeight: "1.2em",
	};
});

const OdysseeDescription = styled(Typography)(({ theme }) => {
	return {
		color: "white",
		fontFamily: "Branding Medium",
		fontSize: "16px",
		lineHeight: "1.25em",
	};
});

export const CTAButton = ({ color, hoverColor, ...props }) => {
	return (
		<InexButton
			{...props}
			sx={{
				padding: 3,
				backgroundColor: (theme) => theme.palette.lightgrey,
				transition: "border-color 0.3s, background 0.3s, color 0.3s,",
				...props.sx,
				"& > p": {
					color: (theme) => theme.palette.secondary.main,
					transition: "color 0.3s,",
					...props.sx?.["& > p"],
				},
				"&:hover": {
					backgroundColor: (theme) => theme.palette.secondary.main,
					border: "none",
					...props.sx?.["&:hover"],
					"& > p": {
						color: "white",
						...props.sx?.["&:hover"]?.["& > p"],
					},
					"& > svg": {
						color: "white",
					},
				},
			}}
		/>
	);
};

const OdysseeCard = ({ ...props }) => {
	const cardRef = React.useRef(null);

	const isVisible = useIsOnScreen(cardRef);
	const isMobile = useIsMobile();

	const contentPadding = React.useMemo(() => {
		return isMobile ? 4 : 6;
	}, [isMobile]);

	return (
		<InexCard
			ref={cardRef}
			sx={{
				background: "linear-gradient(183.02deg, #18122C 8.08%, #402338 96.44%)",
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0,
				boxShadow: (theme) => theme.shadows[26],
			}}
		>
			<Grid
				container
				sx={{
					display: "flex",
				}}
			>
				<Grid item xs={0} md={3.5}>
					<ImageContainer>
						<img src={odysseeImg} alt={"odysseeImg"} />
					</ImageContainer>
				</Grid>
				<Grid container item xs={12} md={8.5} pt={contentPadding}>
					<Grid
						item
						xs={12}
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItem: "flex-start",
							justifyContent: "space-between",
						}}
						pl={contentPadding}
					>
						<Fade in={isVisible} timeout={500}>
							<TagLineTypo>Pour ne plus jamais dire que vous ne saviez pas !</TagLineTypo>
						</Fade>
						<OdysseePriceBlock hasPromo={true}>
							<Typography
								sx={{
									color: "white",
									fontSize: "26px",
									fontFamily: "Branding Bold",
								}}
							>
								De 20€ à 68€ 
							</Typography>
							<Typography
								sx={{
									color: "white",
									//textDecoration: "line-through",
									fontSize: "18px",
									fontFamily: "Branding Medium",
								}}
							>
								selon votre abonnement
							</Typography>
						</OdysseePriceBlock>
					</Grid>
					<Grid item xs={12} pl={contentPadding} pr={contentPadding} mt={isMobile ? 2 : 0}>
						<Fade in={isVisible} timeout={1000}>
							<OdysseeTitle>OVNI, vers une réalité extraterrestre</OdysseeTitle>
						</Fade>
					</Grid>
					<Grid item xs={12} mt={2} pl={contentPadding} mr={contentPadding}>
						<Fade in={isVisible} timeout={1000}>
							<OdysseeDescription>
							Le phénomène OVNI intrigue autant qu’il dérange… Rejeté par certains comme 
							le fruit d’une imagination débordante, revendiqué par d’autres comme une réalité 
							appuyée par des données incontestables, ce sujet déjà complexe s’entoure par ailleurs 
							d’interrogations magistrales pour notre époque....
							</OdysseeDescription>
						</Fade>
					</Grid>
					<Grid item xs={12} mt={4} pl={isMobile ? 0 : contentPadding} p={isMobile ? 1 : 0}>
						<Box
							sx={{
								display: "flex",
								alignItems: isMobile ? "flex-start" : "center",
								justifyContent: "space-evenly",
							}}
						>
							<OdysseeFeaturePoint
								icon={
									<CategoryRounded
										sx={{
											marginTop: "-5px",
										}}
									/>
								}
								text={"5 modules créés pour vous"}
								delay={0}
								sx={{
									marginLeft: 0.5,
									marginRight: 0.5,
								}}
							/>
							{!isMobile && (
								<Divider
									flexItem
									orientation={"vertical"}
									sx={{
										marginTop: 1,
										marginBottom: 2,
										borderRightWidth: 2,
										borderRadius: "1px",
										borderColor: "rgba(255, 255, 255, 0.12)",
									}}
								/>
							)}
							<OdysseeFeaturePoint
								icon={<MovieRounded />}
								text={"5 heures exclusives en vidéo et podcast"}
								delay={200}
								sx={{
									marginLeft: 0.5,
									marginRight: 0.5,
								}}
							/>
							{!isMobile && (
								<Divider
									flexItem
									orientation={"vertical"}
									sx={{
										marginTop: 1,
										marginBottom: 2,
										borderRightWidth: 2,
										borderRadius: "1px",
										borderColor: "rgba(255, 255, 255, 0.12)",
									}}
								/>
							)}
							<OdysseeFeaturePoint
								icon={<FolderCopyRounded />}
								text={"8 heures de ressources supplémentaires"}
								delay={400}
								sx={{
									marginLeft: 0.5,
									marginRight: 0.5,
								}}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} mt={4} pl={contentPadding} mr={contentPadding}>
						<Typography
							sx={{
								color: "#f1c555",
								fontSize: "0.95em",
								fontFamily: "Branding Medium",
								display: "flex",
								gap: "0.5em",
								fontWeight: 700,
								alignItems: "center"
							}}
						>
								<img 
									alt="iconChrono" 
									style={{with: 29, height: 29}}
									src="https://www.inexplore.com/_nuxt/img/icon_chrono.15e2af1.png"
								/>
                                <span>{`J${moment().diff(moment(1736268125000),'days')} ! Profitez maintenant du prix réduit de lancement, +10€ à partir du ${moment(1736268125000)?.format("D MMMM YYYY")}`}</span>
						</Typography>
					</Grid>
					<Grid item xs={12} mt={2} pl={contentPadding} mr={contentPadding}>
						<a href={"/panier?id=1"}>
							<CTAButton
								onClick={() => {
									window.location = "/";
								}}
								variant={"texticon"}
								icon={
									<ShoppingBagOutlined
										sx={{
											fontSize: "26px",
											color: (theme) => theme.palette.secondary.main,
										}}
									/>
								}
								textWithGradient={false}
								disableBackground={true}
								text={"Précommander"}
								sx={{
									width: isMobile ? "100%" : undefined,
									padding: 3.5,
									marginRight: 2,
									flexDirection: "row-reverse",
									backgroundColor: (theme) => theme.palette.yellow,
								}}
							/>
						</a>
						<a href={"https://www.inexplore.com/odyssee/ovni-vers-une-realite-extraterrestre"}>
							<CTAButton
								textWithGradient={false}
								disableBackground={true}
								text={"En savoir plus"}
								sx={{
									width: isMobile ? "100%" : undefined,
									marginTop: isMobile ? 2 : 0,
									padding: 3.5,
								}}
							/>
						</a>
					</Grid>
					<Grid
						item
						xs={12}
						mt={8}
						container
						sx={{
							paddingTop: contentPadding / 2,
							paddingLeft: contentPadding,
							paddingBottom: contentPadding / 2,
							paddingRight: contentPadding,
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
							background: "linear-gradient(183.02deg, #18122C 8.08%, #402338 96.44%)",
						}}
					>
						<Grid
							item
							xs={12}
							sm={4}
							lg={2}
							mt={3}
							mb={3}
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<OdysseeGiftCard image={odysseeImg} width={isMobile ? "65%" : "160px"} />
						</Grid>
						<Grid item xs={12} sm={8} lg={5}>
							<Box
								sx={{
									display: "flex",
									alignItems: "flex-start",
									justifyContent: "center",
									flexDirection: "column",
									color: "white",
								}}
								mt={isMobile ? 5 : 0}
								ml={isMobile ? 0 : 4}
								mr={isMobile ? 0 : 2}
								mb={isMobile ? 2 : 0}
							>
								<Typography
									sx={{
										fontFamily: "Branding Bold",
										fontSize: "22px",
										marginBottom: 2,
									}}
								>
									Aussi disponible en carte cadeau !
								</Typography>
								<Typography
									sx={{
										fontFamily: "Branding Medium",
										fontSize: "16px",
									}}
								>
									Faites plaisir à un proche et offrez-lui cette odyssée.
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={4} mt={4} mb={2}>
							<a href={"/panier?id=1&gift=true"}>
								<CTAButton
									variant={"texticon"}
									icon={
										<CardGiftcardRounded
											sx={{
												fontSize: "26px",
												color: (theme) => theme.palette.secondary.main,
											}}
										/>
									}
									textWithGradient={false}
									disableBackground={true}
									text={"Offrir cette Odyssée"}
									sx={{
										width: "100%",
										minWidth: 230,
										padding: 3.5,
										flexDirection: "row-reverse",
										backgroundColor: "white",
									}}
								/>
							</a>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</InexCard>
	);
};

export default OdysseeCard;
