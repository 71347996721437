import { api } from "./rtkApi";

export const magazinesApi = api.injectEndpoints({
	endpoints: (build) => ({
		getLast4Magazines: build.query({
			query: () => {
				return {
					method: "GET",
					url: `/magazine/last-mags-4`,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetLast4MagazinesQuery } = magazinesApi;
