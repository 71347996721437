import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as MagazineBackground } from "../../../../resources/images/svg/home/magazineBlockBackground.svg";
import InexCard from "../../../InexCard";
import { CardGiftcardRounded } from "@mui/icons-material";
import { CTAButton } from "../odyssee/OdysseeCard";
import { Description, DigitalImage, PriceContainer, Title } from "./DigitalBlock";
import { useIsMobile } from "../../../../hooks/responsive";

const MagazineBlock = ({}) => {
	const isMobile = useIsMobile();

	const link = "https://abonnements.inexplore.com/offrir?selectedAbos=[3]";

	return (
		<InexCard
			sx={{
				position: "relative",
				paddingTop: 0,
				paddingBottom: 0,
				padding: 0,
				boxShadow: (theme) => theme.shadows[26],
				zIndex: 0,
			}}
		>
			<MagazineBackground
				style={{
					position: "absolute",
					left: 0,
					height: "100%",
					opacity: "50%",
				}}
			/>
			<Grid
				container
				sx={{
					backgroundColor: "#A5D0C9",
					zIndex: 1,
				}}
			>
				<Grid item xs={3}>
					<Box
						sx={{
							position: "relative",
							width: "100%",
							height: "100%",
							minHeight: "150px",
							overflow: "hidden",
						}}
					>
						<DigitalImage
							src={`https://medias.inrees.com/img/magazine/last_home.jpg`}
							alt="Digital Png"
							sx={{
								transform: "translate(15%, 5%)",
								height: "90%",
							}}
						/>
					</Box>
				</Grid>
				<Grid
					item
					xs={9}
					sm={6}
					md={7}
					sx={{
						zIndex: 1,
					}}
				>
					<Box
						pl={isMobile ? 2 : 0}
						pt={4}
						pb={4}
						sx={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							height: "100%",
						}}
					>
						<Title>Inexploré Magazine</Title>
						<Description>
							Offrez un abonnement de 6 mois à Inexploré magazine, en format papier et numérique sur notre
							application mobile.
						</Description>
						{!isMobile && (
							<Box
								sx={{
									marginTop: (theme) => theme.spacing(1),
								}}
							>
								<a target="_blank" href={link}>
									<CTAButton
										variant={"texticon"}
										icon={
											<CardGiftcardRounded
												sx={{
													fontSize: "26px",
													color: (theme) => theme.palette.secondary.main,
												}}
											/>
										}
										textWithGradient={false}
										disableBackground={true}
										text={"Offrir une carte cadeau Inexploré magazine"}
										sx={{
											minWidth: 230,
											padding: 3.5,
											flexDirection: "row-reverse",
											backgroundColor: "#F1C555",
										}}
										textSx={{
											textTransform: "none",
										}}
									/>
								</a>
							</Box>
						)}
					</Box>
				</Grid>
				<Grid
					xs={12}
					sm={3}
					md={2}
					sx={{
						zIndex: 1,
					}}
				>
					<PriceContainer
						sx={{
							marginTop: isMobile ? 0 : "20px",
						}}
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontFamily: "Branding Semibold",
								color: (theme) => theme.palette.secondary.main,
							}}
						>
							6 mois d'abonnement
						</Typography>
						<Typography
							sx={{
								fontSize: "30px",
								fontFamily: "Branding Bold",
								color: (theme) => theme.palette.secondary.main,
							}}
						>
							18,00€
						</Typography>
					</PriceContainer>
				</Grid>
				{isMobile && (
					<Grid
						xs={12}
						m={2}
						sx={{
							zIndex: 1,
						}}
					>
						<Box
							sx={{
								marginTop: (theme) => theme.spacing(1),
							}}
						>
							<a target="_blank" href={link}>
								<CTAButton
									variant={"texticon"}
									icon={
										<CardGiftcardRounded
											sx={{
												fontSize: "26px",
												color: (theme) => theme.palette.secondary.main,
											}}
										/>
									}
									textWithGradient={false}
									disableBackground={true}
									text={"Offrir une carte cadeau Inexploré magazine"}
									sx={{
										width: "100%",
										minWidth: 230,
										padding: 3.5,
										flexDirection: "row-reverse",
										backgroundColor: "#F1C555",
									}}
									textSx={{
										textTransform: "none",
									}}
								/>
							</a>
						</Box>
					</Grid>
				)}
			</Grid>
		</InexCard>
	);
};

export default MagazineBlock;
