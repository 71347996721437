import React, { Suspense } from "react";
import Home from "./Home";
import { Alert, Grid, Snackbar } from "@mui/material";
import Header from "../components/header/Header";
import { BrowserRouter, NavLink, Route, Router, Routes, useNavigate } from "react-router-dom";
import { useIsMobile } from "../hooks/responsive";
import { useDispatch, useSelector } from "react-redux";
import {
	getShowSnackbar,
	getSnackbarSeverity,
	getSnackbarText,
	setShowSnackbar,
} from "../store/reducers/snackbarSlice";
import Footer from "../components/footer/Footer";
import CheckSending from "../routes/CheckSending";
import PaymentSuccess from "../routes/PaymentSuccess";
import PayementError from "../routes/PaymentError";
import PaymentPending from "../routes/PaymentPending";
import { RoutesSteps } from "../hooks/useStep";
import PayOrder from "./PayOrder";

const RoutesDefinitions = () => {
	const isMobile = useIsMobile();

	const showSnackBar = useSelector(getShowSnackbar);
	const snackbarText = useSelector(getSnackbarText);
	const snackbarSeverity = useSelector(getSnackbarSeverity) || "success";

	const dispatch = useDispatch();
	const handleCloseSnackbar = () => {
		dispatch(setShowSnackbar(false));
	};

	return (
		<div style={{ position: "relative" }}>
			<Header />
			<Grid container sx={{ flexWrap: "nowrap" }}>
				<Grid item xs>
					<Suspense>
						<BrowserRouter>
							<Routes>
								<Route path="/" element={<Home />} />
								{RoutesSteps()}
								<Route path="/payment-success" element={<PaymentSuccess />} />
								<Route path="/payment-error" element={<PayementError />} />
								<Route path="/payment-pending" element={<PaymentPending />} />
								<Route path="/check-sending" element={<CheckSending />} />
								<Route path="/payer-ma-commande" element={<PayOrder />} />
								<Route path="*" element={<Home />} />
							</Routes>
						</BrowserRouter>
					</Suspense>
				</Grid>
			</Grid>
			<Footer />
			<Snackbar
				open={showSnackBar}
				autoHideDuration={2000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
					{snackbarText}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default RoutesDefinitions;
