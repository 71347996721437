import React from "react";
import { Box, Card, styled, useTheme } from "@mui/material";
import { useIsMobile } from "../hooks/responsive";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";

const Border = styled(Box)(({ theme, selected }) => {
	return {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		border: `1px solid ${theme.palette.secondary.main}`,
		pointerEvents: "none",
		zIndex: 1,
		opacity: selected === true ? 1 : 0,
		transition: "opacity 0.4s",
		borderRadius: "inherit",
	};
});

const DisabledFilter = styled(Box)(({ theme }) => {
	return {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		pointerEvents: "none",
		zIndex: 1,
		opacity: 0.2,
		backgroundColor: "grey",
	};
});

const InexCard = React.forwardRef(({ children, sx, selected = false, disabled = false, ...props }, ref) => {
	const theme = useTheme();
	const isMobile = useIsMobile();

	const [disableRipple, setDisableRipple] = React.useState(false);

	const rippleRef = React.useRef(null);

	const onRippleStart = (e) => {
		if (!!props.onClick) rippleRef.current.start(e);
	};
	const onRippleStop = (e) => {
		if (!!props.onClick) rippleRef.current.stop(e);
	};

	// Disable Ripple effect only after 500ms
	const timeoutRef = React.useRef(null);
	React.useEffect(() => {
		if (timeoutRef.current !== null) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
		setTimeout(() => {
			setDisableRipple(selected === true);
			timeoutRef.current = null;
		}, 500);
	}, [selected]);

	return (
		<Card
			ref={ref}
			sx={{
				position: "relative",
				boxShadow: (theme) => theme.shadows[25],
				padding: (theme) => theme.spacing(isMobile ? 3 : 5),
				width: "100%",
				paddingTop: theme.spacing(isMobile ? 5 : 7),
				paddingBottom: theme.spacing(isMobile ? 5 : 7),
				overflow: "visible",
				"&:hover": {
					cursor: typeof props.onClick === "function" && disabled !== true ? "pointer" : undefined,
				},
				...sx,
			}}
			{...props}
			onMouseDown={(e) => {
				if (props.onClick) onRippleStart(e);
				props.onMouseDown && props.onMouseDown(e);
			}}
			onMouseUp={(e) => {
				if (props.onClick) onRippleStop(e);
				props.onMouseUp && props.onMouseUp(e);
			}}
		>
			{disabled === true && <DisabledFilter />}
			<Border selected={selected} />
			{children}
			<TouchRipple
				ref={rippleRef}
				center={false}
				sx={{
					color: (theme) => (disableRipple === true ? "transparent" : theme.palette.grey["400"]),
				}}
			/>
		</Card>
	);
});

export default InexCard;
