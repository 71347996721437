import React from "react";
import { Alert, Button, IconButton, Typography, styled, CircularProgress } from "@mui/material";
import { GradientTypo } from "./header/Header";

const Inextypo = styled(Typography)(({ theme, disabled }) => {
	return {
		fontWeight: 700,
		background: disabled === false ? undefined : theme.palette.grey["400"],
		overflow: "hidden",
		fontFamily: "Branding Bold",
		fontSize: "40px",
	};
});

const InexButton = ({
	variant = "text",
	icon = undefined,
	text = undefined,
	disabled = false,
	textWithGradient = true,
	textSx = {},
	disableBackground = false,
	loading = false,
	loadingColor = "primary",
	...props
}) => {
	const Component = variant === "icon" ? IconButton : Button;

	if (variant === "icon" && icon === undefined)
		return (
			<Alert severity={"error"} sx={{ color: "black" }}>
				{"Ce bouton nécessite un icône"}
			</Alert>
		);
	if (variant === "text" && text === undefined)
		return (
			<Alert severity={"error"} sx={{ color: "black" }}>
				{"Ce bouton nécessite un 'text'"}
			</Alert>
		);

	const TextComponent = textWithGradient ? GradientTypo : Inextypo;

	return (
		<Component
			{...props}
			disabled={disabled}
			variant={"outlined"}
			sx={{
				background: (theme) =>
					disableBackground
						? null
						: disabled === true
							? theme.palette.grey[300]
							: theme.palette.secondary.main,
				borderRadius: "30px",
				height: "45px",
				minWidth: "60px",
				transition: "box-shadow 0.3s",
				border: (theme) => (!!props.sx?.background ? `1px solid ${theme.palette.secondary.main}` : "none"),
				"&:hover": {
					background: (theme) =>
						disableBackground
							? null
							: disabled === true
								? theme.palette.grey[300]
								: "linear-gradient(90deg, #ee7992 0%, #f1ae65 50%, #f4c54d 100%)",
					border: (theme) => (!!props.sx?.background ? `1px solid ${theme.palette.secondary.main}` : "none"),
					"& > p": {
						background: "white",
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "transparent",
						...props.sx?.["&:hover"]?.["& > p"],
					},
					...props.sx?.["&:hover"],
				},
				...props.sx,
			}}
		>
			{loading === true ? (
				<CircularProgress sx={{ padding: (theme) => theme.spacing(0.75), color: loadingColor }} />
			) : variant === "text" || variant === "texticon" ? (
				<>
					<TextComponent
						disabled={disabled}
						sx={{
							fontSize: 16,
							fontFamily: "Branding Medium",
							textTransform: "lowercase",
							"&:first-letter": {
								textTransform: "capitalize",
							},
							[props.sx?.flexDirection === "row-reverse" ? "marginLeft" : "marginRight"]: (theme) =>
								variant === "texticon" ? theme.spacing(2) : 0,
							...textSx,
							lineHeight: 1.2,
						}}
					>
						{text}
					</TextComponent>
					{variant === "texticon" ? (
						typeof icon === "string" ? (
							<img alt={"buttonIcon"} src={icon} />
						) : (
							icon
						)
					) : null}
				</>
			) : typeof icon === "string" ? (
				<img alt={"buttonIcon"} src={icon} />
			) : (
				icon
			)}
			{}
		</Component>
	);
};

export default InexButton;
