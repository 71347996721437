import React from "react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as ShoppingBag } from "../../resources/images/svg/shoppingBag.svg";

const BasketButton = () => {
	const isSmallScreen = useMediaQuery("(max-width: 1350px)");

	return (
		<Button
			onClick={() => {
				window.location = "/panier";
			}}
			sx={{
				color: (theme) => theme.palette.secondary.main,
				background: "#FBF0E5",
				border: "none",
				borderRadius: "25px",
				height: "45px",
				display: "flex",
				alignItems: "center",
			}}
		>
			<ShoppingBag
				style={{
					marginLeft: isSmallScreen ? "0px" : "8px",
				}}
			/>
			{!isSmallScreen && (
				<Typography
					sx={{
						fontWeight: 700,
						overflow: "hidden",
						fontSize: 16,
						fontFamily: "Branding Medium",
						textTransform: "lowercase",
						"&:first-letter": {
							textTransform: "capitalize",
						},
						lineHeight: 1.2,
						marginRight: 1,
						marginLeft: 1,
					}}
				>
					{"Panier"}
				</Typography>
			)}
		</Button>
	);
};

export default BasketButton;
