import React from "react";
import { Grid, styled, Box } from "@mui/material";
import { ReactComponent as Earth } from "../../resources/images/svg/earth.svg";
import logo from "../../resources/images/svg/inexplore-logo-white.svg";
import BurgerButton from "./BurgerButton";
import MenuPanelContent from "./MenuPanelContent";
import UniversesList from "./UniversesList";

const Wrapper = styled(Grid)(({ theme }) => {
	return {
		position: "fixed",
		width: "100%",
		zIndex: 30,
	};
});
const Container = styled(Grid)(({ theme }) => {
	return {
		width: "100%",
		height: "70px",
		position: "absolute",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		background: "linear-gradient(90deg,#162a41 0,#8f6c9f)",
		padding: "0 10px",
		zIndex: 32,
	};
});

const MenuPanel = styled(Box)(() => {
	return {
		position: "fixed",
		height: "calc(100% - 2.55rem)",
		width: "100%",
		background: "linear-gradient(90deg,#162a41 0,#8f6c9f)",

		top: "70px",
		transition: "left 0.3s ease-out",
		left: "-100%",
		"&.isOpen": {
			left: 0,
		},
	};
});

const SafeAreaView = styled(Box)(() => {
	return {
		height: 70,
	};
});

const SmallHeader = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isUniverseOpen, setIsUniverseOpen] = React.useState(false);

	const redirect = (url) => {
		window.location.href = `${process.env.REACT_APP_INEXPLORE_URL}${url}`;
	};

	return (
		<React.Fragment>
			<Wrapper>
				<Container>
					<Grid item sx={{ width: 39, paddingX: "9.5px" }}>
						<Box
							onMouseEnter={() => setIsUniverseOpen(true)}
							onMouseLeave={() => setIsUniverseOpen(false)}
							sx={{ position: "relative", cursor: "pointer" }}
						>
							<Earth color={"white"} />
							<UniversesList
								absoluteStyleProps={{
									position: "absolute",
									top: 0,
									left: -10,
									transform: "translateY(-10%)",
									paddingTop: 6,
								}}
								isOpen={isUniverseOpen}
								inSubMenu={true}
							/>
						</Box>
					</Grid>
					<Grid item onClick={() => redirect("/")} sx={{ cursor: "pointer" }}>
						<img
							src={logo}
							className=""
							alt="logo"
							style={{ height: "54px", width: "120px", marginBottom: "5px" }}
						/>
					</Grid>
					<Grid item sx={{ paddingX: "9.5px" }}>
						<BurgerButton isOpen={isOpen} setIsOpen={setIsOpen} />
					</Grid>
					<MenuPanel className={`${!!isOpen ? "isOpen" : "closed"}`}>
						<MenuPanelContent redirect={redirect} />
					</MenuPanel>
				</Container>
			</Wrapper>
			<SafeAreaView />
		</React.Fragment>
	);
};

export default SmallHeader;
