import { api } from "./rtkApi";

export const appApi = api.injectEndpoints({
	endpoints: (build) => ({
		getUnivers: build.query({
			query: () => {
				return {
					method: "GET",
					url: `/common/univers`,
				};
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue?.univers;
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetUniversQuery } = appApi;
