import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addProduct as addProductToStore,
	changeProductQuantity,
	getProducts,
	getTotalPrice,
	setBasketProductId,
} from "../store/reducers/basketSlice";
import {
	useAddProductToBasketMutation,
	useEditProductInBasketMutation,
	useGetBasketQuery,
	useLazyCheckBasketStockAvailabilityQuery,
} from "../store/api/basket";
import { useAuthToken, useIsLoggedIn } from "./auth";
import { useSearchParams } from "react-router-dom";
import { useLazyGetProductQuery } from "../store/api/product";
import moment from "moment";
import { useGetUserOdysseesQuery, useLazyGetUserHasOdysseeQuery } from "../store/api/user";

const checkProductIsDifferent = (oldP, newP) => {
	return (
		oldP.quantity !== newP.quantity ||
		oldP.infos.giftInfos !== newP.infos.gift ||
		oldP.infos.giftInfos !== newP.infos.gift
	);
};

export const useCheckStock = (redirectToBasketOnError = false) => {
	const { products } = useBasket();
	const [checkStock, { isLoading, isFetching }] = useLazyCheckBasketStockAvailabilityQuery();

	const checkStockFunction = React.useCallback(
		(callback = (value) => {}) => {
			if (products.length <= 0) {
				callback(null);
				return;
			}
			const ids = products.map((p) => p.infos.id);
			checkStock({ ids }).then((response) => {
				const availableMap = {};
				Object.keys(response.data).forEach((key) => {
					availableMap[key] = { ...response.data[key] };
				});
				const result = {};
				products.forEach((basketProduct) => {
					availableMap[basketProduct.infos.id].totalAvailable -= basketProduct.quantity;
					const isNumerique = basketProduct.infos.numerique === 1;
					result[basketProduct.id] = {
						...availableMap[basketProduct.infos.id],
						availableAfterPurchase: availableMap[basketProduct.infos.id].totalAvailable,
						availableStock: response.data[basketProduct.infos.id].totalAvailable,
						isNumerique,
						isError: availableMap[basketProduct.infos.id].totalAvailable < 0 && !isNumerique,
					};
				});
				if (
					redirectToBasketOnError === true &&
					Object.keys(result).some((key) => result[key].isError === true)
				) {
					window.location = "/panier";
				} else {
					callback(result);
				}
			});
		},
		[products, redirectToBasketOnError],
	);

	return [checkStockFunction, isLoading, isFetching];
};

// returns useful functions to manipulate the basket state and get the products inside.
export const useBasket = () => {
	const products = useSelector(getProducts);
	const [totalPrice, totalPromo, totalPriceBeforePromo, totalPriceHT] = useSelector(getTotalPrice);

	const dispatch = useDispatch();

	const addProduct = React.useCallback(({ product, quantity, ...rest }) => {
		const _id = (Math.random() + 1).toString(36).substring(7);
		dispatch(addProductToStore({ _id, infos: { ...product }, quantity, ...rest }));
		return _id;
	}, []);

	const changeQuantity = React.useCallback(({ id, quantity }) => {
		dispatch(changeProductQuantity({ id, quantity }));
	}, []);

	const getProduct = React.useCallback(
		(id) => {
			return products.find((e) => e.id === id);
		},
		[products],
	);

	return React.useMemo(() => {
		return {
			products,
			totalPrice,
			totalPromo,
			totalPriceBeforePromo,
			totalPriceHT,
			addProduct,
			changeQuantity,
			getProduct,
		};
	}, [products, totalPrice, totalPromo, totalPriceBeforePromo, addProduct, changeQuantity]);
};

// Adds the product (if found in the query params) in the store.
export const useAddProductInQueryParams = (setProductId) => {
	const { products, addProduct, changeQuantity } = useBasket();
	const [searchParams] = useSearchParams();
	const [getProduct] = useLazyGetProductQuery();
	// Code to add the product in the basket if it was sent in the queryParams of the page.
	React.useEffect(() => {
		const id = searchParams.get("id");
		if (id) {
			getProduct({ id }).then(({ data, ...rest }) => {
				const product = data.product;
				let quantity = parseInt(searchParams.get("quantity") ?? 1, 10);
				if (product.idcat === 13) quantity = 1;
				const gift = searchParams.get("gift") === "true";

				const remaining = product.stock - product.vendu;
				const isNumerique = product.numerique === 1;

				const basketProducts = products.filter((p) => p.infos.id === product.id && p.infos.gift !== true);
				// If there is no matching products or we try to add an Odyssée as a gift
				if (basketProducts.length === 0 || (product.idcat === 13 && gift)) {
					const available = isNumerique || (remaining > 0 && remaining >= quantity);
					const idInBasket = addProduct({ product: { ...product, gift: !!gift }, quantity, available });
					setProductId(idInBasket, false, !available);
				} else {
					// if the product is an Odyssée
					if (product.idcat === 13) {
						const notAGift = basketProducts.find((p) => p.infos.gift !== true);
						// If the product is not a gift and we try to add another one that is not a gift
						if (!!notAGift && gift !== true) {
							const available =
								isNumerique || (remaining > 0 && remaining >= notAGift.quantity + quantity);
							setProductId(notAGift._id, true, !available);
						}
					} else {
						// We know there is a basketProduct with the same product inside : change quantity in the basketProduct.
						// technically, there can only be one product in the array here.
						const basketProduct = basketProducts[0];
						const available =
							isNumerique || (remaining > 0 && remaining >= basketProduct.quantity + quantity);
						if (available) {
							changeQuantity({ id: basketProduct.id, quantity: basketProduct.quantity + quantity });
							setProductId(basketProduct._id, false, !available);
						} else {
							setProductId(basketProduct._id, true, !available);
						}
					}
				}

				window.history.replaceState && window.history.replaceState(null, "", window.location.pathname);
			});
		}
	}, []);
};

// This hooks connects to the store and syncs the products at all time with the API.
// it is going to be executed when the user connected too, which will automatically sync the basket with the API.
export const useSyncBasketToAPI = (setStockResult = () => {}) => {
	const isLoggedIn = useIsLoggedIn();
	const authToken = useAuthToken();

	const dispatch = useDispatch();

	const { products, addProduct, changeQuantity } = useBasket();
	const basketProductsRef = React.useRef(products);
	const [firstSyncDone, setFirstSyncDone] = React.useState(false);

	const { data, isLoading, isFetching } = useGetBasketQuery({ authToken }, { skip: !authToken });

	React.useEffect(() => {
		if (isLoggedIn && authToken) {
			if (data) {
				const findProductIndexInStore = (id) => {
					return basketProductsRef.current?.findIndex((p) => p.id === id);
				};

				//console.log("API :", data.products);
				// loop on api products.
				data.products.map((p, index) => {
					// formattedData.products[index] = { ...data.products[index] };
					// set info to be readable
					// formattedData.products[index].infos = JSON.parse(data.products[index].infos.replace('"', '"'));

					// const apiProduct = formattedData.products[index];
					const apiProduct = p;
					// check product is in the local store.
					const idx = findProductIndexInStore(apiProduct.itemId);
					// element is in the store.
					if (idx !== -1) {
						// if element is in store, we update it either on api or in the store based on the updatedAt dates.
						const basketProduct = basketProductsRef.current[idx];
						const localDate = moment(basketProduct.infos.updatedAt);
						const apiDate = moment(apiProduct.infos.updatedAt);
						if (apiDate.isAfter(localDate)) {
							// update local store to the value in the API.
							dispatch(
								changeProductQuantity({
									id: basketProduct.id,
									quantity: apiProduct.quantity,
									infos: apiProduct.infos,
									updatedAt: apiProduct.infos.updatedAt,
									synched: true,
								}),
							);
						} else if (apiDate.isBefore(localDate)) {
							// update api to the value in the local store.
							editProductInBasket({
								id: basketProduct.id,
								quantity: basketProduct.quantity,
								infos: { ...basketProduct.infos, updatedAt: basketProduct.updatedAt },
								authToken,
							});
						} else {
							// Nothing to do. Everything is in sync
						}
					} else {
						// element is not in the store we need to add it in.
						addProduct({
							id: apiProduct.itemId,
							product: apiProduct.infos,
							quantity: apiProduct.quantity,
							updatedAt: apiProduct.infos.updatedAt,
							synched: true,
						});
					}
				});
				setFirstSyncDone(true);
			} else if (!isLoading && !isFetching) {
				const now = Date.now();
				// if the basket is null : we upload all the products we have in the api to the store.
				basketProductsRef.current.forEach((apiProduct) => {
					addProductToBasket({
						productId: apiProduct.infos.id,
						quantity: apiProduct.quantity,
						idcat: apiProduct.infos.idcat,
						infos: { ...apiProduct.infos, updatedAt: now },
						authToken,
					}).then(({ data }) => {
						// on return of the api call, get the itemId and keep it in the store for later.
						if (data?.produitadd) {
							dispatch(
								setBasketProductId({
									oldId: apiProduct.id,
									id: data.produitadd.itemId,
									updatedAt: now,
									synched: true,
								}),
							);
						}
					});
				});
				setFirstSyncDone(true);
			}
		}
	}, [data, isLoading, isFetching, isLoggedIn, authToken]);

	const [addProductToBasket] = useAddProductToBasketMutation();
	const [editProductInBasket] = useEditProductInBasketMutation();

	const [checkStock] = useCheckStock();

	const syncStoreWithAPI = React.useCallback(() => {
		// Only do that when the user is logged in and the authToken was fetched.
		if (isLoggedIn && authToken) {
			const productsAdded = [];
			const productsEdited = [];
			const productsRemoved = [];

			// finding the products that were added / edited.
			products.forEach((product) => {
				const oldIndex = basketProductsRef.current.findIndex((oP) => oP.id === product.id);
				if (oldIndex === -1 || product.synched !== true) {
					productsAdded.push(product);
				} else {
					const oldProduct = basketProductsRef.current[oldIndex];
					if (checkProductIsDifferent(oldProduct, product)) productsEdited.push(product);
				}
			});
			// finding the products that were removed.
			basketProductsRef.current.forEach((oldProduct) => {
				const newIndex = products.findIndex((p) => p.id === oldProduct.id);
				if (newIndex === -1) productsRemoved.push(oldProduct);
			});

			const promisesArr = [];

			// products added are synced with the API.
			productsAdded.forEach((basketProduct) => {
				if (basketProduct.synched !== true && basketProduct.available === true) {
					const now = Date.now();
					promisesArr.push(
						new Promise((res, rej) => {
							// add product in API
							addProductToBasket({
								productId: basketProduct.infos.id,
								quantity: basketProduct.quantity,
								idcat: basketProduct.infos.idcat,
								infos: { ...basketProduct.infos, updatedAt: now },
								authToken,
							}).then(({ data }) => {
								// on return of the api call, get the itemId and keep it in the store for later.
								if (data?.produitadd) {
									dispatch(
										setBasketProductId({
											oldId: basketProduct.id,
											id: data.produitadd.itemId,
											updatedAt: now,
											synched: true,
										}),
									);
								}
								res();
							});
						}),
					);
				}
			});

			// products removed and edited are synced with the API.
			productsEdited.forEach((basketProduct) => {
				const now = Date.now();
				if (basketProduct.synched === true) {
					promisesArr.push(
						editProductInBasket({
							id: basketProduct.id,
							quantity: basketProduct.quantity,
							infos: { ...basketProduct.infos, updatedAt: now },
							authToken,
						}),
					);
				}
			});
			productsRemoved.forEach((basketProduct) => {
				if (basketProduct.synched === true) {
					promisesArr.push(
						editProductInBasket({
							id: basketProduct.id,
							quantity: 0,
							authToken,
						}),
					);
				}
			});
			basketProductsRef.current = products;
			Promise.all(promisesArr).then(() => {
				checkStock((data) => {
					setStockResult?.(data);
				});
			});
		}
	}, [isLoggedIn, authToken, products, checkStock]);

	// Syncs the local changes of the basket with the API, only when the first synchronization of the store and the basket was done.
	React.useEffect(() => {
		if (isLoggedIn && authToken && firstSyncDone) syncStoreWithAPI();
	}, [isLoggedIn, products, authToken, firstSyncDone]);
};

export const useCheckUserHasProductAlready = () => {
	const isLoggedIn = useIsLoggedIn();
	const authToken = useAuthToken();

	const { products } = useBasket();

	const { data: userOdyssees } = useGetUserOdysseesQuery({ skip: !isLoggedIn || !authToken });

	const shouldCheckUserProducts = React.useMemo(() => {
		return !!isLoggedIn && !!authToken;
	}, [isLoggedIn, authToken]);

	const productsAlreadyInAccount = React.useMemo(() => {
		if (shouldCheckUserProducts && userOdyssees && userOdyssees.length > 0) {
			return products?.filter((basketProduct) => {
				return userOdyssees.find((e) => {
					return (
						e.idproduit === basketProduct.infos.id &&
						basketProduct.infos.gift !== true &&
						basketProduct.synched === true
					);
				});
			});
		}
		return null;
	}, [shouldCheckUserProducts, userOdyssees, products]);

	// if the second value is different from null => redirect user to Basket page.
	return [shouldCheckUserProducts, productsAlreadyInAccount];
};
