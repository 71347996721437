import React from "react";

import { Route } from "react-router-dom";

const BasketScreen = React.lazy(() => import("../routes/Basket"));
const AddressChoiceScreen = React.lazy(() => import("../routes/Delivery"));
const TipChoiceScreen = React.lazy(() => import("../routes/TipChoice"));
const PaymentScreen = React.lazy(() => import("../routes/Payment"));

export const ROUTES_STEP_DEFINITION = {
	BASKET: {
		path: "/panier",
		nextRoute: "/livraison",
		stepNb: 0,
		component: <BasketScreen />,
		label: "Panier",
	},
	DELIVERY: {
		path: "/livraison",
		nextRoute: "/don",
		stepNb: 1,
		component: <AddressChoiceScreen />,
		label: "Livraison",
	},
	TIP: {
		path: "/don",
		nextRoute: "/paiement",
		stepNb: 2,
		component: <TipChoiceScreen />,
		label: "Coup de pouce",
	},
	PAYMENT: {
		path: "/paiement",
		nextRoute: null,
		stepNb: 3,
		component: <PaymentScreen />,
		label: "Paiement",
	},
};

export const RoutesSteps = () =>
	Object.values(ROUTES_STEP_DEFINITION).map((route) => (
		<Route key={route.path} path={route.path} element={route.component} />
	));

export const useStep = (pageName) => {
	return ROUTES_STEP_DEFINITION?.[pageName];
};
